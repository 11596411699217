import React, { useContext, useState } from "react";
import { bool, object } from "prop-types";
import styled from "styled-components";

import { CurrentThemeContext } from "../../../common/ThemeContextWrapper";
import { CloseButton, MenuContentHeader } from "../../../Menu/MenuContentContainer";
import OpaqueWindowContent from "../../../common/styles/OpaqueWindowContent";
import OpaqueWindowContainerWrapper from "../../../common/styles/OpaqueWindowContainer";
import { SelectDropdown } from "../Mechs/components/MechCategoryStyles";
import { SPRITE_KEYS } from "../../../common/styles/SpriteImage";

const contributorText = "Support with +2 patron tier. Created by ";

export const contributorList = {
    PINKHYENA: {
        isContributorAsset: true,
        title: "Pinkhyena",
        url: "https://twitter.com/LuckyTyphlo",
    },
    CORVON: {
        isContributorAsset: true,
        title: "Corvon",
        url: "https://discord.gg/JUF47G5",
    },
    CATOFMANYFACES: {
        isContributorAsset: true,
        title: "Cat of Many Faces",
        url: "https://discord.gg/JUF47G5",
    },
    FRESH_SLAV: {
        prefixText: "Created by ",
        title: "Fresh Slav",
        url: "https://discord.gg/JUF47G5",
    },
    BDF_MFR: {
        prefixText: "Commissioned by Clem Cuddlecakes ",
        title: "The BDF PDF",
        url: "https://captaincuddlecakes.itch.io/the-bdf-pdf",
        secondaryPrefixText: "Original by",
        secondaryTitle: "Zerovirus",
        secondaryUrl: "https://www.artstation.com/drazelic",
    }
};

export const CreditLink = styled.a`
    display: block;
    cursor: pointer;
    font-family: arial;
    font-size: 15px;
    font-weight: bold;
    text-align: left;
    color: #CCC;
    margin: 10px auto;

    &.retro-terminal {
        span {
            color: #33FF33;
        }
    }

    &.default {
        span {
            color: #5878D4;
        }
    }

    @media print {
        display: none;
    }
`;

CreditLink.displayName = "CreditLink";

export function createArtCreditLink({partLabel, originalArtCredit, displayTheme}) {
    return (
        <>
        <CreditLink
            className={displayTheme ? "retro-terminal" : "default"}
            href={originalArtCredit.url}
            target="_blank"
            rel="noopener noreferrer"
            key={`${partLabel}-${originalArtCredit.title}`}
        >
            {partLabel}: {originalArtCredit.isContributorAsset ? contributorText : originalArtCredit.prefixText ? originalArtCredit.prefixText : "Original design by "}
            &nbsp;
            <span>{originalArtCredit.title}</span>
        </CreditLink>
        {
            originalArtCredit.secondaryTitle ? (
                <CreditLink
                    className={displayTheme ? "retro-terminal" : "default"}
                    href={originalArtCredit.secondaryUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    key={`${partLabel}-${originalArtCredit.secondaryTitle}`}
                >
                    {originalArtCredit.secondaryPrefixText ? originalArtCredit.secondaryPrefixText : "Original design by "}
                    &nbsp;
                    <span>{originalArtCredit.secondaryTitle}</span>
                </CreditLink>
            ) : null
        }
        </>
    );
}

function ArtCreditLink({ unit, brandList, isNPCCategory, isCustomCategory, isCustomPlusCategory, isCoreImageView = null }) {
    const { displayTheme } = useContext(CurrentThemeContext);
    const [showPartCredits, setShowPartCredits] = useState(false);

    let artCreditContentArray = [];

    if (isNPCCategory) {
        if (unit.weaponValue !== "NONE" && brandList[unit.brandValue][unit.frameValue].weapon[unit.weaponValue]?.originalArtCredit) {
            artCreditContentArray.push(createArtCreditLink({
                partLabel: brandList[unit.brandValue][unit.frameValue].labels.weapon,
                originalArtCredit: brandList[unit.brandValue][unit.frameValue].weapon[unit.weaponValue]?.originalArtCredit,
                displayTheme,
            }));
        }
        if (unit.secondaryWeaponValue !== "NONE" && brandList[unit.brandValue][unit.frameValue].secondaryWeapon[unit.secondaryWeaponValue]?.originalArtCredit) {
            artCreditContentArray.push(createArtCreditLink({
                partLabel: brandList[unit.brandValue][unit.frameValue].labels.secondaryWeapon,
                originalArtCredit: brandList[unit.brandValue][unit.frameValue].secondaryWeapon[unit.secondaryWeaponValue]?.originalArtCredit,
                displayTheme,
            }));
        }
        if (unit.chassisValue !== "NONE" && brandList[unit.brandValue][unit.frameValue].chassis[unit.chassisValue]?.originalArtCredit) {
            artCreditContentArray.push(createArtCreditLink({
                partLabel: brandList[unit.brandValue][unit.frameValue].labels.chassis,
                originalArtCredit: brandList[unit.brandValue][unit.frameValue].chassis[unit.chassisValue]?.originalArtCredit,
                displayTheme,
            }));
        }
        if (unit.camo1Value !== "NONE" && brandList[unit.brandValue][unit.frameValue].camo[unit.camo1Value]?.originalArtCredit) {
            artCreditContentArray.push(createArtCreditLink({
                partLabel: brandList[unit.brandValue][unit.frameValue].labels.camo1,
                originalArtCredit: brandList[unit.brandValue][unit.frameValue].camo[unit.camo1Value]?.originalArtCredit,
                displayTheme,
            }));
        }
        if (unit.camo2Value !== "NONE" && brandList[unit.brandValue][unit.frameValue].camo[unit.camo2Value]?.originalArtCredit) {
            artCreditContentArray.push(createArtCreditLink({
                partLabel: brandList[unit.brandValue][unit.frameValue].labels.camo2,
                originalArtCredit: brandList[unit.brandValue][unit.frameValue].camo[unit.camo2Value]?.originalArtCredit,
                displayTheme,
            }));
        }
        if (unit.camo3Value !== "NONE" && brandList[unit.brandValue][unit.frameValue].camo[unit.camo3Value]?.originalArtCredit) {
            artCreditContentArray.push(createArtCreditLink({
                partLabel: brandList[unit.brandValue][unit.frameValue].labels.camo3,
                originalArtCredit: brandList[unit.brandValue][unit.frameValue].camo[unit.camo3Value]?.originalArtCredit,
                displayTheme,
            }));
        }
        if (unit.camo4Value !== "NONE" && brandList[unit.brandValue][unit.frameValue].camo[unit.camo4Value]?.originalArtCredit) {
            artCreditContentArray.push(createArtCreditLink({
                partLabel: brandList[unit.brandValue][unit.frameValue].labels.camo4,
                originalArtCredit: brandList[unit.brandValue][unit.frameValue].camo[unit.camo4Value]?.originalArtCredit,
                displayTheme,
            }));
        }
    } else if (isCustomCategory) {
        if (unit.armLeftValue !== "NONE" && brandList.arms[unit.armLeftValue]?.originalArtCredit) {
            artCreditContentArray.push(createArtCreditLink({
                partLabel: "LEFT ARM",
                originalArtCredit: brandList.arms[unit.armLeftValue].originalArtCredit,
                displayTheme,
            }));
        }
        if (unit.armRightValue !== "NONE" && brandList.arms[unit.armRightValue]?.originalArtCredit) {
            artCreditContentArray.push(createArtCreditLink({
                partLabel: "RIGHT ARM",
                originalArtCredit: brandList.arms[unit.armRightValue].originalArtCredit,
                displayTheme,
            }));
        }
        if (unit.headValue !== "NONE" && brandList.head[unit.headValue]?.originalArtCredit) {
            artCreditContentArray.push(createArtCreditLink({
                partLabel: "HEAD",
                originalArtCredit: brandList.head[unit.headValue].originalArtCredit,
                displayTheme,
            }));
        }
        if (unit.chassisValue !== "NONE" && brandList.chassis[unit.chassisValue]?.originalArtCredit) {
            artCreditContentArray.push(createArtCreditLink({
                partLabel: "CHASSIS",
                originalArtCredit: brandList.chassis[unit.chassisValue].originalArtCredit,
                displayTheme,
            }));
        }
        if (unit.legsValue !== "NONE" && brandList.legs[unit.legsValue]?.originalArtCredit) {
            artCreditContentArray.push(createArtCreditLink({
                partLabel: "LEGS",
                originalArtCredit: brandList.legs[unit.legsValue].originalArtCredit,
                displayTheme,
            }));
        }
        if (unit.weaponValue !== "NONE" && brandList.weapon[unit.stanceValue][unit.weaponValue]?.originalArtCredit) {
            artCreditContentArray.push(createArtCreditLink({
                partLabel: "MAIN WEAPON",
                originalArtCredit: brandList.weapon[unit.stanceValue][unit.weaponValue].originalArtCredit,
                displayTheme,
            }));
        }
        if (unit.secondaryWeaponValue !== "NONE" && brandList.secondaryWeapon[unit.secondaryWeaponValue]?.originalArtCredit) {
            artCreditContentArray.push(createArtCreditLink({
                partLabel: "AUX WEAPON",
                originalArtCredit: brandList.secondaryWeapon[unit.secondaryWeaponValue].originalArtCredit,
                displayTheme,
            }));
        }
        if (unit.rearMountValue !== "NONE" && brandList.rearMount[unit.rearMountValue]?.originalArtCredit) {
            artCreditContentArray.push(createArtCreditLink({
                partLabel: "REAR MOUNT",
                originalArtCredit: brandList.rearMount[unit.rearMountValue].originalArtCredit,
                displayTheme,
            }));
        }
        if (unit.accessory1Value !== "NONE" && brandList.accessory[unit.accessory1Value]?.originalArtCredit) {
            artCreditContentArray.push(createArtCreditLink({
                partLabel: "ACCESSORY 1",
                originalArtCredit: brandList.accessory[unit.accessory1Value].originalArtCredit,
                displayTheme,
            }));
        }
        if (unit.accessory2Value !== "NONE" && brandList.accessory[unit.accessory2Value]?.originalArtCredit) {
            artCreditContentArray.push(createArtCreditLink({
                partLabel: "ACCESSORY 2",
                originalArtCredit: brandList.accessory[unit.accessory2Value].originalArtCredit,
                displayTheme,
            }));
        }
        if (unit.accessory3Value !== "NONE" && brandList.accessory[unit.accessory3Value]?.originalArtCredit) {
            artCreditContentArray.push(createArtCreditLink({
                partLabel: "ACCESSORY 3",
                originalArtCredit: brandList.accessory[unit.accessory3Value].originalArtCredit,
                displayTheme,
            }));
        }
        if (unit.accessory4Value !== "NONE" && brandList.accessory[unit.accessory4Value]?.originalArtCredit) {
            artCreditContentArray.push(createArtCreditLink({
                partLabel: "ACCESSORY 4",
                originalArtCredit: brandList.accessory[unit.accessory4Value].originalArtCredit,
                displayTheme,
            }));
        }
    } else if (isCustomPlusCategory) {
        if (unit.armLeftValue !== "NONE" && brandList.leftArm[unit.armLeftValue]?.originalArtCredit) {
            artCreditContentArray.push(createArtCreditLink({
                partLabel: "LEFT ARM",
                originalArtCredit: brandList.leftArm[unit.armLeftValue].originalArtCredit,
                displayTheme,
            }));
        }
        if (unit.armRightValue !== "NONE" && brandList.rightArm[unit.armRightValue]?.originalArtCredit) {
            artCreditContentArray.push(createArtCreditLink({
                partLabel: "RIGHT ARM",
                originalArtCredit: brandList.rightArm[unit.armRightValue].originalArtCredit,
                displayTheme,
            }));
        }
        if (unit.headValue !== "NONE" && brandList.head[unit.headValue]?.originalArtCredit) {
            artCreditContentArray.push(createArtCreditLink({
                partLabel: "HEAD",
                originalArtCredit: brandList.head[unit.headValue].originalArtCredit,
                displayTheme,
            }));
        }
        if (unit.chassisValue !== "NONE" && brandList.chassis[unit.chassisValue]?.originalArtCredit) {
            artCreditContentArray.push(createArtCreditLink({
                partLabel: "CHASSIS",
                originalArtCredit: brandList.chassis[unit.chassisValue].originalArtCredit,
                displayTheme,
            }));
        }
        if (unit.legsValue !== "NONE" && brandList.legs[unit.legsValue]?.originalArtCredit) {
            artCreditContentArray.push(createArtCreditLink({
                partLabel: "LEGS",
                originalArtCredit: brandList.legs[unit.legsValue].originalArtCredit,
                displayTheme,
            }));
        }
        if (unit.weaponValue !== "NONE" && brandList.weapon[unit.stanceValue][unit.weaponValue]?.originalArtCredit) {
            artCreditContentArray.push(createArtCreditLink({
                partLabel: "MAIN WEAPON",
                originalArtCredit: brandList.weapon[unit.stanceValue][unit.weaponValue].originalArtCredit,
                displayTheme,
            }));
        }
        if (unit.secondaryWeaponValue !== "NONE" && brandList.secondaryWeapon[unit.secondaryWeaponValue]?.originalArtCredit) {
            artCreditContentArray.push(createArtCreditLink({
                partLabel: "AUX WEAPON",
                originalArtCredit: brandList.secondaryWeapon[unit.secondaryWeaponValue].originalArtCredit,
                displayTheme,
            }));
        }
        if (unit.rearMountValue !== "NONE" && brandList.rearMount[unit.rearMountValue]?.originalArtCredit) {
            artCreditContentArray.push(createArtCreditLink({
                partLabel: "REAR MOUNT",
                originalArtCredit: brandList.rearMount[unit.rearMountValue].originalArtCredit,
                displayTheme,
            }));
        }
        if (unit.accessory1Value !== "NONE" && brandList.accessory[unit.accessory1Value]?.originalArtCredit) {
            artCreditContentArray.push(createArtCreditLink({
                partLabel: "ACCESSORY 1",
                originalArtCredit: brandList.accessory[unit.accessory1Value].originalArtCredit,
                displayTheme,
            }));
        }
        if (unit.accessory2Value !== "NONE" && brandList.accessory[unit.accessory2Value]?.originalArtCredit) {
            artCreditContentArray.push(createArtCreditLink({
                partLabel: "ACCESSORY 2",
                originalArtCredit: brandList.accessory[unit.accessory2Value].originalArtCredit,
                displayTheme,
            }));
        }
        if (unit.accessory3Value !== "NONE" && brandList.accessory[unit.accessory3Value]?.originalArtCredit) {
            artCreditContentArray.push(createArtCreditLink({
                partLabel: "ACCESSORY 3",
                originalArtCredit: brandList.accessory[unit.accessory3Value].originalArtCredit,
                displayTheme,
            }));
        }
        if (unit.accessory4Value !== "NONE" && brandList.accessory[unit.accessory4Value]?.originalArtCredit) {
            artCreditContentArray.push(createArtCreditLink({
                partLabel: "ACCESSORY 4",
                originalArtCredit: brandList.accessory[unit.accessory4Value].originalArtCredit,
                displayTheme,
            }));
        }
    } else {
        if (unit.accessory1Value !== "NONE" && brandList[unit.brandValue][unit.frameValue].accessory[unit.accessory1Value]?.originalArtCredit) {
            artCreditContentArray.push(createArtCreditLink({
                partLabel: brandList[unit.brandValue][unit.frameValue].labels.accessory1,
                originalArtCredit: brandList[unit.brandValue][unit.frameValue].accessory[unit.accessory1Value]?.originalArtCredit,
                displayTheme,
            }));
        }
        if (unit.accessory2Value !== "NONE" && brandList[unit.brandValue][unit.frameValue].accessory[unit.accessory2Value]?.originalArtCredit) {
            artCreditContentArray.push(createArtCreditLink({
                partLabel: brandList[unit.brandValue][unit.frameValue].labels.accessory2,
                originalArtCredit: brandList[unit.brandValue][unit.frameValue].accessory[unit.accessory2Value]?.originalArtCredit,
                displayTheme,
            }));
        }
        if (unit.weaponValue !== "NONE" && brandList[unit.brandValue][unit.frameValue].weapon[unit.stanceValue][unit.weaponValue]?.originalArtCredit) {
            artCreditContentArray.push(createArtCreditLink({
                partLabel: brandList[unit.brandValue][unit.frameValue].labels.weapon,
                originalArtCredit: brandList[unit.brandValue][unit.frameValue].weapon[unit.stanceValue][unit.weaponValue]?.originalArtCredit,
                displayTheme,
            }));
        }
        if (unit.secondaryWeaponValue !== "NONE" && brandList[unit.brandValue][unit.frameValue].secondaryWeapon[unit.secondaryWeaponValue]?.originalArtCredit) {
            artCreditContentArray.push(createArtCreditLink({
                partLabel: brandList[unit.brandValue][unit.frameValue].labels.secondaryWeapon,
                originalArtCredit: brandList[unit.brandValue][unit.frameValue].secondaryWeapon[unit.secondaryWeaponValue]?.originalArtCredit,
                displayTheme,
            }));
        }
        if (unit.rearMountValue !== "NONE" && brandList[unit.brandValue][unit.frameValue].rearMount[unit.rearMountValue]?.originalArtCredit) {
            artCreditContentArray.push(createArtCreditLink({
                partLabel: brandList[unit.brandValue][unit.frameValue].labels.rearMount,
                originalArtCredit: brandList[unit.brandValue][unit.frameValue].rearMount[unit.rearMountValue]?.originalArtCredit,
                displayTheme,
            }));
        }
        if (unit.headValue !== "NONE" && brandList[unit.brandValue][unit.frameValue].head[unit.headValue]?.originalArtCredit) {
            artCreditContentArray.push(createArtCreditLink({
                partLabel: brandList[unit.brandValue][unit.frameValue].labels.head,
                originalArtCredit: brandList[unit.brandValue][unit.frameValue].head[unit.headValue]?.originalArtCredit,
                displayTheme,
            }));
        }
        if (unit.chassisValue !== "NONE" && brandList[unit.brandValue][unit.frameValue].chassis[unit.chassisValue]?.originalArtCredit) {
            artCreditContentArray.push(createArtCreditLink({
                partLabel: brandList[unit.brandValue][unit.frameValue].labels.chassis,
                originalArtCredit: brandList[unit.brandValue][unit.frameValue].chassis[unit.chassisValue]?.originalArtCredit,
                displayTheme,
            }));
        }

        if (typeof isCoreImageView === "number" && brandList[unit.brandValue][unit.frameValue].coreImg[isCoreImageView]?.originalArtCredit) {
            artCreditContentArray.push(createArtCreditLink({
                partLabel: "CORE IMAGE",
                originalArtCredit: brandList[unit.brandValue][unit.frameValue].coreImg[isCoreImageView]?.originalArtCredit,
                displayTheme,
            }));
        }
    }

    return (
        <React.Fragment>
            {showPartCredits ?
                <OpaqueWindowContainerWrapper style={{ zIndex: 1000 }}>
                    <MenuContentHeader>
                        <h3>PART CREDITS</h3>
                        <CloseButton
                            className={SPRITE_KEYS.xButton}
                            alt="Close"
                            onClick={() => setShowPartCredits(false)}
                        />
                    </MenuContentHeader>

                    <OpaqueWindowContent>
                        {artCreditContentArray}
                    </OpaqueWindowContent>
                </OpaqueWindowContainerWrapper>
                : null
            }

            <SelectDropdown
                className={displayTheme ? "retro-terminal" : "default"}
                disabled={artCreditContentArray.length === 0}
            >
                <label
                    htmlFor="part-credits"
                    onClick={() => setShowPartCredits(artCreditContentArray.length !== 0)}
                    style={{ cursor: "pointer" }}
                >
                    PART CREDITS
                </label>
            </SelectDropdown>
        </React.Fragment>
    );
}


ArtCreditLink.propTypes = {
    unit: object.isRequired,
    brandList: object.isRequired,
    isNPCCategory: bool,
    isCustomCategory: bool,
};

ArtCreditLink.defaultProps = {
    isNPCCategory: false,
    isCustomCategory: false,
};

export default ArtCreditLink;
