import BaseURL from "./images/Hosting-BaseURL";
import { shadowTypes } from "../styles/UnitShadow";
import { contributorList } from "../Category/components/ArtCreditLink";

const imagePath = `${BaseURL}/Mechs/Custom/BDF Jian`;

// BODY
const body1HImage = `${imagePath}/Chassis - 1H1H stance.png`;
const body2HImage = `${imagePath}/Chassis - 2H stance.png`;

// HEADS
const headJianImage = `${imagePath}/heads/Head - Jian.png`;
const headAngularImage = `${imagePath}/heads/Head - Angular.png`;
const headBlackbeardImage = `${imagePath}/heads/Head - Blackbeard.png`;
const headBlackspotImage = `${imagePath}/heads/Head - Blackspot.png`;
const headBriarImage = `${imagePath}/heads/Head - Briar.png`;
const headCalibanImage = `${imagePath}/heads/Head - Caliban.png`;
const headDungamImage = `${imagePath}/heads/Head - Dungam.png`;
const headFactoryStandardImage = `${imagePath}/heads/Head - Factory Standard.png`;
const headFrontlineImage = `${imagePath}/heads/Head - Frontline.png`;
const headJudicatorImage = `${imagePath}/heads/Head - Judicator.png`;
const headKazuImage = `${imagePath}/heads/Head - Kazu.png`;
const headLaborerImage = `${imagePath}/heads/Head - Laborer.png`;
const headLudonautImage = `${imagePath}/heads/Head - Ludonaut.png`;
const headMaliceImage = `${imagePath}/heads/Head - Malice.png`;
const headMecha1Image = `${imagePath}/heads/Head - Mecha 1.png`;
const headMecha2Image = `${imagePath}/heads/Head - Mecha 2.png`;
const headMecha3Image = `${imagePath}/heads/Head - Mecha 3.png`;
const headMecha4Image = `${imagePath}/heads/Head - Mecha 4.png`;
const headMecha5Image = `${imagePath}/heads/Head - Mecha 5.png`;
const headMecha6Image = `${imagePath}/heads/Head - Mecha 6.png`;
const headMecha7Image = `${imagePath}/heads/Head - Mecha 7.png`;
const headMecha8Image = `${imagePath}/heads/Head - Mecha 8.png`;
const headNaturalLawImage = `${imagePath}/heads/Head - Natural Law.png`;
const headNaturalLawHairImage = `${imagePath}/heads/Head - Natural Law Hair.png`;
const headOgreImage = `${imagePath}/heads/Head - Ogre.png`;
const headPaladinImage = `${imagePath}/heads/Head - Paladin.png`;
const headPredatorImage = `${imagePath}/heads/Head - Predator.png`;
const headRainImage = `${imagePath}/heads/Head - Rain Transformed.png`;
const headRainHairImage = `${imagePath}/heads/Head - Rain Transformed Hair.png`;
const headScarab2Image = `${imagePath}/heads/Head - Scarab 2.png`;
const headScopedogImage = `${imagePath}/heads/Head - Scopedog.png`;
const headVisionaryImage = `${imagePath}/heads/Head - Visionary.png`;

// WEAPONS
// LEFT
const weaponsLeftHandImage = `${imagePath}/weapons/left/Left - Hand.png`;
const weaponsLeftAxeImage = `${imagePath}/weapons/left/Left - Axe.png`;
const weaponsLeftSMGImage = `${imagePath}/weapons/left/Left - SMG.png`;
const weaponsLeftSkullnadeImage = `${imagePath}/weapons/left/Left - Skullnade.png`;
const weaponsLeftGhostrunnerImage = `${imagePath}/weapons/left/Left - Ghostrunner.png`;
// RIGHT
const weaponsRightHandImage = `${imagePath}/weapons/right/Right - Hand.png`;
const weaponsRightAxeImage = `${imagePath}/weapons/right/Right - Axe.png`;
const weaponsRightSMGImage = `${imagePath}/weapons/right/Right - SMG.png`;
const weaponsRightSkullnadeImage = `${imagePath}/weapons/right/Right - Skullnade.png`;
const weaponsRightGhostrunnerImage = `${imagePath}/weapons/right/Right - Ghostrunner.png`;

// 2H
const weapons2HAK447Image = `${imagePath}/weapons/2h/2H - AK447.png`;
const weapons2HAutoShottyImage = `${imagePath}/weapons/2h/2H - Auto Shotty.png`;
const weapons2HCPRImage = `${imagePath}/weapons/2h/2H - CPR.png`;
const weapons2HBoltThrowerImage = `${imagePath}/weapons/2h/2H - Bolt Thrower.png`;
const weapons2HShotgunImage = `${imagePath}/weapons/2h/2H - Shotgun.png`;
const weapons2HLauncherImage = `${imagePath}/weapons/2h/2H - Launcher.png`;
const weapons2HGrenadeLauncherImage = `${imagePath}/weapons/2h/2H - Grenade Launcher.png`;
const weapons2HDoubleLauncherImage = `${imagePath}/weapons/2h/2H - Double Launcher.png`;

// ACCESSORIES
const accessoryScabbardsImage = `${imagePath}/accessories/Accessory - Scabbards.png`;
const accessoryScabbardsUnderImage = `${imagePath}/accessories/Accessory - Scabbards - Under.png`;
const accessoryTacticalGear1HImage = `${imagePath}/accessories/Accessory - Tactical Gear 1H1H.png`;
const accessoryTacticalGear2HImage = `${imagePath}/accessories/Accessory - Tactical Gear 2H.png`;
const accessoryTacticalGearUnderImage = `${imagePath}/accessories/Accessory - Tactical Gear - Under.png`;
const accessoryCoinGrenades1HImage = `${imagePath}/accessories/Accessory - Coin Grenades 1H1H.png`;
const accessoryCoinGrenades2HImage = `${imagePath}/accessories/Accessory - Coin Grenades 2H.png`;

// GLOW
const glowCoinGrenades1HImage = `${imagePath}/glows/Glow - Coin Grenades 1H1H.png`;
const glowCoinGrenades2HImage = `${imagePath}/glows/Glow - Coin Grenades 2H.png`;
const glowShotgunRibbonImage = `${imagePath}/glows/Glow - Shotgun Ribbon.png`;
const glowGrenadeLauncherImage = `${imagePath}/glows/Glow - Grenade Launcher.png`;
const glowLeftGhostrunnerImage = `${imagePath}/glows/Glow - Left Ghostrunner.png`;
const glowTacImage = `${imagePath}/glows/Glow - Tac Glow.png`;

const previewImage = `${imagePath}/preview.png`;
const restrictedPreviewImage = `${imagePath}/preview-restricted.png`;

const coreImage = `${imagePath}/BDF_JIAN_by_AzMiLion.png`;
const coreImage1 = `${imagePath}/BDF_Jian_core_color-Volkenhammer.png`;

const bipedLegsShadow = {
    size: shadowTypes.SUPREMELY_LARGE,
    bottom: "9px",
    right: "28px",
};

export default {
    rearMountsAreChassis: true,
    rearMountsAreChassisSpecial: "stanceValue",
    hasMultipleStances: true,
    previewImg: previewImage,
    restrictedPreviewImg: restrictedPreviewImage,
    coreImg: [
        {
            src: coreImage,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "AzMiLion",
                url: "https://discord.gg/JUF47G5",
            },
        },
        {
            src: coreImage1,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "Volkenhammer",
                url: "https://discord.gg/JUF47G5",
            },
        },
    ],
    defaultValues: `{
        "chassisValue": "1",
        "headValue": "0",
        "rearMountValue": "NONE",
        "weaponValue": "1",
        "secondaryWeaponValue": "NONE",
        "accessory1Value": "7",
        "accessory2Value": "NONE",
        "accessory3Value": "4",
        "accessory4Value": "NONE",
        "stanceValue": "1",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "JIAN",
            src: headJianImage,
        },
        {
            value: 1,
            label: "ANGULAR",
            src: headAngularImage,
        },
        {
            value: 2,
            label: "BLACKBEARD",
            src: headBlackbeardImage,
        },
        {
            value: 3,
            label: "BLACKSPOT",
            src: headBlackspotImage,
        },
        {
            value: 4,
            label: "BRIAR",
            src: headBriarImage,
        },
        {
            value: 5,
            label: "CALIBAN",
            src: headCalibanImage,
        },
        {
            value: 6,
            label: "DUNGAM",
            src: headDungamImage,
        },
        {
            value: 7,
            label: "FACTORY STANDARD",
            src: headFactoryStandardImage,
        },
        {
            value: 8,
            label: "FRONTLINE",
            src: headFrontlineImage,
        },
        {
            value: 9,
            label: "JUDICATOR",
            src: headJudicatorImage,
        },
        {
            value: 10,
            label: "KAZU",
            src: headKazuImage,
        },
        {
            value: 11,
            label: "LABORER",
            src: headLaborerImage,
        },
        {
            value: 12,
            label: "LUDONAUT",
            src: headLudonautImage,
        },
        {
            value: 13,
            label: "MALICE",
            src: headMaliceImage,
        },
        {
            value: 14,
            label: "MECHA 1",
            src: headMecha1Image,
        },
        {
            value: 15,
            label: "MECHA 2",
            src: headMecha2Image,
        },
        {
            value: 16,
            label: "MECHA 3",
            src: headMecha3Image,
        },
        {
            value: 17,
            label: "MECHA 4",
            src: headMecha4Image,
        },
        {
            value: 18,
            label: "MECHA 5",
            src: headMecha5Image,
        },
        {
            value: 19,
            label: "MECHA 6",
            src: headMecha6Image,
        },
        {
            value: 20,
            label: "MECHA 7",
            src: headMecha7Image,
        },
        {
            value: 21,
            label: "MECHA 8",
            src: headMecha8Image,
        },
        {
            value: 22,
            label: "NATURAL LAW",
            src: headNaturalLawImage,
        },
        {
            value: 23,
            label: "NATURAL LAW HAIR",
            src: headNaturalLawHairImage,
        },
        {
            value: 24,
            label: "OGRE",
            src: headOgreImage,
        },
        {
            value: 25,
            label: "PALADIN",
            src: headPaladinImage,
        },
        {
            value: 26,
            label: "PREDATOR",
            src: headPredatorImage,
        },
        {
            value: 27,
            label: "RAIN",
            src: headRainImage,
        },
        {
            value: 28,
            label: "RAIN HAIR",
            src: headRainHairImage,
        },
        {
            value: 29,
            label: "SCARAB",
            src: headScarab2Image,
        },
        {
            value: 30,
            label: "SCOPEDOG",
            src: headScopedogImage,
        },
        {
            value: 31,
            label: "VISIONARY",
            src: headVisionaryImage,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "1H1H",
            src: body1HImage,
            shadowDetails: bipedLegsShadow,
            originalArtCredit: contributorList.BDF_MFR,
        },
        {
            value: 1,
            label: "2H",
            src: body2HImage,
            shadowDetails: bipedLegsShadow,
            originalArtCredit: contributorList.BDF_MFR,
        },
    ],
    weapon: {
        "0": [
            {
                value: 0,
                label: "HAND",
                src: weaponsLeftHandImage,
            },
            {
                value: 1,
                label: "AXE",
                src: weaponsLeftAxeImage,
            },
            {
                value: 2,
                label: "SMG",
                src: weaponsLeftSMGImage,
            },
            {
                value: 3,
                label: "SKULLNADE",
                src: weaponsLeftSkullnadeImage,
            },
            {
                value: 4,
                label: "GHOSTRUNNER",
                src: weaponsLeftGhostrunnerImage,
            },
        ],
        "1": [
            {
                value: 0,
                label: "AK447",
                src: weapons2HAK447Image,
            },
            {
                value: 1,
                label: "SHOTGUN",
                src: weapons2HShotgunImage,
            },
            {
                value: 2,
                label: "AUTO SHOTTY",
                src: weapons2HAutoShottyImage,
            },
            {
                value: 3,
                label: "CPR",
                src: weapons2HCPRImage,
            },
            {
                value: 4,
                label: "BOLT THROWER",
                src: weapons2HBoltThrowerImage,
            },
            {
                value: 5,
                label: "GRENADE LAUNCHER",
                src: weapons2HGrenadeLauncherImage,
            },
            {
                value: 6,
                label: "LAUNCHER",
                src: weapons2HLauncherImage,
            },
            {
                value: 7,
                label: "DOUBLE LAUNCHER",
                src: weapons2HDoubleLauncherImage,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "HAND",
            src: weaponsRightHandImage,
        },
        {
            value: 1,
            label: "AXE",
            src: weaponsRightAxeImage,
        },
        {
            value: 2,
            label: "SMG",
            src: weaponsRightSMGImage,
        },
        {
            value: 3,
            label: "SKULLNADE",
            src: weaponsRightSkullnadeImage,
        },
        {
            value: 4,
            label: "GHOSTRUNNER",
            backSrc: weaponsRightGhostrunnerImage,
        },
    ],
    rearMount: [],
    accessory: [
        {
            value: 0,
            label: "SCABBARDS",
            src: accessoryScabbardsImage,
            backSrc: accessoryScabbardsUnderImage,
        },
        {
            value: 1,
            label: "TACTICAL GEAR - 1H1H",
            src: accessoryTacticalGear1HImage,
            backSrc: accessoryTacticalGearUnderImage,
        },
        {
            value: 2,
            label: "TACTICAL GEAR - 2H",
            src: accessoryTacticalGear2HImage,
            backSrc: accessoryTacticalGearUnderImage,
        },
        {
            value: 3,
            label: "COIN GRENADES - 1H1H",
            src: accessoryCoinGrenades1HImage,
        },
        {
            value: 4,
            label: "COIN GRENADES - 2H",
            src: accessoryCoinGrenades2HImage,
        },
        {
            value: 5,
            label: "GLOW - COIN GRENADES - 1H1H",
            src: glowCoinGrenades1HImage,
        },
        {
            value: 6,
            label: "GLOW - COIN GRENADES - 2H",
            src: glowCoinGrenades2HImage,
        },
        {
            value: 7,
            label: "GLOW - SHOTGUN RIBBON",
            frontSrc: glowShotgunRibbonImage,
        },
        {
            value: 8,
            label: "GLOW - GRENADE LAUNCHER",
            frontSrc: glowGrenadeLauncherImage,
        },
        {
            value: 9,
            label: "GLOW - LEFT GHOSTRUNNER",
            frontSrc: glowLeftGhostrunnerImage,
        },
        {
            value: 10,
            label: "GLOW - TAC",
            src: glowTacImage,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "GLOWS / ACCESSORIES",
            value: "ACCESSORIES",
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "HEAD",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "WEAPON",
            value: "weapon",
        },
        {
            label: "1H RIGHT",
            value: "secondaryWeapon",
        },
        {
            label: "GLOW 1",
            value: "accessory1",
            isGlow: true,
        },
        {
            label: "GLOW 2",
            value: "accessory2",
            isGlow: true,
        },
        {
            label: "ACCESSORY 1",
            value: "accessory3",
        },
        {
            label: "ACCESSORY 2",
            value: "accessory4",
        },
    ],
    labels: {
        "head": "HEAD",
        "chassis": "STANCE",
        "rearMount": "REAR MOUNT",
        "weapon": "WEAPON",
        "secondaryWeapon": "1H RIGHT",
        "accessory1": "GLOW 1",
        "accessory2": "GLOW 2",
        "accessory3": "ACCESSORY 1",
        "accessory4": "ACCESSORY 2",
    },
};
