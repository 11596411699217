import { shadowTypes } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

const imagePath = `${BaseURL}/Mechs/SSC/Amber-Phantom`;

// BODY
const bodyImage = `${imagePath}/Chassis.png`;

// HEADS
const headAmberPhantomImage = `${imagePath}/heads/Head - Amber Phantom.png`;
const headAngularImage = `${imagePath}/heads/Head - Angular.png`;
const headBlackspotImage = `${imagePath}/heads/Head - Blackspot.png`;
const headBriarImage = `${imagePath}/heads/Head - Briar.png`;
const headCalibanImage = `${imagePath}/heads/Head - Caliban.png`;
const headFrontlineImage = `${imagePath}/heads/Head - Frontline.png`;
const headHornedImage = `${imagePath}/heads/Head - Horned.png`;
const headHornedHairImage = `${imagePath}/heads/Head - Horned Hair.png`;
const headJudicatorImage = `${imagePath}/heads/Head - Judicator.png`;
const headKazuImage = `${imagePath}/heads/Head - Kazu.png`;
const headLaborerImage = `${imagePath}/heads/Head - Laborer.png`;
const headLudonautImage = `${imagePath}/heads/Head - Ludonaut.png`;
const headMaliceImage = `${imagePath}/heads/Head - Malice.png`;
const headMecha1Image = `${imagePath}/heads/Head - Mecha 1.png`;
const headMecha2Image = `${imagePath}/heads/Head - Mecha 2.png`;
const headMecha3Image = `${imagePath}/heads/Head - Mecha 3.png`;
const headMecha4Image = `${imagePath}/heads/Head - Mecha 4.png`;
const headMecha5Image = `${imagePath}/heads/Head - Mecha 5.png`;
const headMecha6Image = `${imagePath}/heads/Head - Mecha 6.png`;
const headMecha7Image = `${imagePath}/heads/Head - Mecha 7.png`;
const headMecha8Image = `${imagePath}/heads/Head - Mecha 8.png`;
const headNaturalLawImage = `${imagePath}/heads/Head - Natural Law.png`;
const headNaturalLawHairImage = `${imagePath}/heads/Head - Natural Law Hair.png`;
const headPredatorImage = `${imagePath}/heads/Head - Predator.png`;
const headRainTransformedImage = `${imagePath}/heads/Head - Rain Transformed.png`;
const headRainTransformedHairImage = `${imagePath}/heads/Head - Rain Transformed Hair.png`;
const headScarabImage = `${imagePath}/heads/Head - Scarab.png`;
const headVanguardImage = `${imagePath}/heads/Head - Vanguard.png`;

// ARMS
// UPPER
const armsUpperKatanasImage = `${imagePath}/arms/upper/Upper Arms - Katanas.png`;
const armsUpperKukriImage = `${imagePath}/arms/upper/Upper Arms - Kukri.png`;
const armsUpperLaserBladesImage = `${imagePath}/arms/upper/Upper Arms - Laser Blades.png`;
const armsUpperMonowireImage = `${imagePath}/arms/upper/Upper Arms - Monowire.png`;
const armsUpperRadialBladesImage = `${imagePath}/arms/upper/Upper Arms - Radial Blades.png`;
const armsUpperSOCOMsImage = `${imagePath}/arms/upper/Upper Arms - SOCOMs.png`;
const armsUpperSOCOMsFrontImage = `${imagePath}/arms/upper/Upper Arms - SOCOMs front.png`;
const armsUpperStaffImage = `${imagePath}/arms/upper/Upper Arms - Staff.png`;
const armsUpperTargettingImage = `${imagePath}/arms/upper/Upper Arms - Targetting.png`;

// LOWER
const armsLowerAssaultRifleImage = `${imagePath}/arms/lower/Lower Arms - Assault Rifle.png`;
const armsLowerDecksweeperImage = `${imagePath}/arms/lower/Lower Arms - Decksweeper.png`;
const armsLowerKatanasImage = `${imagePath}/arms/lower/Lower Arms - Katanas.png`;
const armsLowerMonowireImage = `${imagePath}/arms/lower/Lower Arms - Monowire.png`;
const armsLowerRadialBladesImage = `${imagePath}/arms/lower/Lower Arms - Radial Blades.png`;
const armsLowerRailRifleImage = `${imagePath}/arms/lower/Lower Arms - Rail Rifle.png`;
const armsLowerSmartRifleImage = `${imagePath}/arms/lower/Lower Arms - Smart Rifle.png`;
const armsLowerSOCOMsImage = `${imagePath}/arms/lower/Lower Arms - SOCOMs.png`;

// ACCESSORY
const accAmberPhantomTrailsImage = `${imagePath}/accessory/Accessories Over - Amber Phantom Trails.png`;
const accAmberPhantomTrailsUnderImage = `${imagePath}/accessory/Accessories Under - Amber Phantom Trails.png`;
const accBlackWitchRobesImage = `${imagePath}/accessory/Accessories Over - Black Witch Robes.png`;
const accBlackWitchRobesUnderImage = `${imagePath}/accessory/Accessories Under - Black Witch Robes.png`;
const accCloakImage = `${imagePath}/accessory/Accessories Over - Cloak.png`;
const accCloakUnderImage = `${imagePath}/accessory/Accessories Under - Cloak.png`;

// GLOWS
const glowLaserBladesImage = `${imagePath}/glows/Glows - Laser Blades.png`;
const glowLowerMonowireImage = `${imagePath}/glows/Glows - Lower Monowire.png`;
const glowUpperMonowireImage = `${imagePath}/glows/Glows - Upper Monowire.png`;
const glowQuadMonowiresImage = `${imagePath}/glows/Glows - Quad Monowires.png`;
const glowLowerRadialBladesImage = `${imagePath}/glows/Glows - Lower Radial Blades.png`;
const glowUpperRadialBladesImage = `${imagePath}/glows/Glows - Upper Radial Blades.png`;
const glowQuadRadialBladesImage = `${imagePath}/glows/Glows - Quad Radial Blades.png`;
const glowRailRifleImage = `${imagePath}/glows/Glows - Rail Rifle.png`;
const glowTargettingImage = `${imagePath}/glows/Glows - Targetting.png`;
const glowTrailsImage = `${imagePath}/glows/Glows - Trails.png`;

const previewImage = `${imagePath}/preview.png`;

const core1Image = `${imagePath}/amber_phantom_corebook-MechaMaria64.png`;
const core2Image = `${imagePath}/Amber_Phantom-WannabeWaifu.png`;
const core3Image = `${imagePath}/Amber_Phantom-vivi.png`;
const core4Image = `${imagePath}/Amber_Phantom-AzMiLion.png`;
const core5Image = `${imagePath}/Amber_Phantom_Core_Paintjob_Saurusss.png`;

const bipedLegsShadow = {
    size: shadowTypes.LARGE,
    bottom: "17px",
    right: "24px",
};

export default {
    previewImg: previewImage,
    coreImg: [
        {
            src: core1Image,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "MechaMaria64",
                url: "https://discord.gg/JUF47G5",
            },
        },
        {
            src: core2Image,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "Wannabe Waifu",
                url: "https://discord.gg/JUF47G5",
            },
        },
        {
            src: core3Image,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "Vivivision",
                url: "https://discord.gg/JUF47G5",
            },
        },
        {
            src: core4Image,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "AzMiLion",
                url: "https://discord.gg/JUF47G5",
            },
        },
        {
            src: core5Image,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "Saurusss",
                url: "https://discord.gg/JUF47G5",
            },
        },
    ],
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "0",
        "rearMountValue": "0",
        "weaponValue": "6",
        "secondaryWeaponValue": "1",
        "accessory1Value": "NONE",
        "accessory2Value": "NONE",
        "accessory3Value": "NONE",
        "accessory4Value": "NONE",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "AMBER PHANTOM",
            src: headAmberPhantomImage,
        },
        {
            value: 1,
            label: "ANGULAR",
            src: headAngularImage,
        },
        {
            value: 2,
            label: "BLACKSPOT",
            src: headBlackspotImage,
        },
        {
            value: 3,
            label: "BRIAR",
            src: headBriarImage,
        },
        {
            value: 4,
            label: "CALIBAN",
            src: headCalibanImage,
        },
        {
            value: 5,
            label: "FRONTLINE",
            src: headFrontlineImage,
        },
        {
            value: 6,
            label: "HORNED",
            src: headHornedImage,
        },
        {
            value: 7,
            label: "HORNED HAIR",
            src: headHornedHairImage,
        },
        {
            value: 8,
            label: "JUDICATOR",
            src: headJudicatorImage,
        },
        {
            value: 9,
            label: "KAZU",
            src: headKazuImage,
        },
        {
            value: 10,
            label: "LABORER",
            src: headLaborerImage,
        },
        {
            value: 11,
            label: "LUDONAUT",
            src: headLudonautImage,
        },
        {
            value: 12,
            label: "MALICE",
            src: headMaliceImage,
        },
        {
            value: 13,
            label: "MECHA 1",
            src: headMecha1Image,
        },
        {
            value: 14,
            label: "MECHA 2",
            src: headMecha2Image,
        },
        {
            value: 15,
            label: "MECHA 3",
            src: headMecha3Image,
        },
        {
            value: 16,
            label: "MECHA 4",
            src: headMecha4Image,
        },
        {
            value: 17,
            label: "MECHA 5",
            src: headMecha5Image,
        },
        {
            value: 18,
            label: "MECHA 6",
            src: headMecha6Image,
        },
        {
            value: 19,
            label: "MECHA 7",
            src: headMecha7Image,
        },
        {
            value: 20,
            label: "MECHA 8",
            src: headMecha8Image,
        },
        {
            value: 21,
            label: "NATURAL LAW",
            src: headNaturalLawImage,
        },
        {
            value: 22,
            label: "NATURAL LAW HAIR",
            src: headNaturalLawHairImage,
        },
        {
            value: 23,
            label: "PREDATOR",
            src: headPredatorImage,
        },
        {
            value: 24,
            label: "RAIN TRANSFORMED",
            src: headRainTransformedImage,
        },
        {
            value: 25,
            label: "RAIN TRANSFORMED HAIR",
            src: headRainTransformedHairImage,
        },
        {
            value: 26,
            label: "SCARAB",
            src: headScarabImage,
        },
        {
            value: 27,
            label: "VANGUARD",
            src: headVanguardImage,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "AMBER PHANTOM",
            src: bodyImage,
            shadowDetails: bipedLegsShadow,
        }
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "ASSAULT RIFLE",
                foremostSrc: armsLowerAssaultRifleImage,
            },
            {
                value: 1,
                label: "DECKSWEEPER",
                foremostSrc: armsLowerDecksweeperImage,
            },
            {
                value: 2,
                label: "KATANAS",
                foremostSrc: armsLowerKatanasImage,
            },
            {
                value: 3,
                label: "MONOWIRE",
                src: armsLowerMonowireImage,
            },
            {
                value: 4,
                label: "RADIAL BLADES",
                src: armsLowerRadialBladesImage,
            },
            {
                value: 5,
                label: "RAIL RIFLE",
                foremostSrc: armsLowerRailRifleImage,
            },
            {
                value: 6,
                label: "SMART RIFLE",
                foremostSrc: armsLowerSmartRifleImage,
            },
            {
                value: 7,
                label: "SOCOMS",
                foremostSrc: armsLowerSOCOMsImage,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "KATANAS",
            midSrc: armsUpperKatanasImage,
        },
        {
            value: 1,
            label: "KUKRI",
            midSrc: armsUpperKukriImage,
        },
        {
            value: 2,
            label: "LASER BLADES",
            midSrc: armsUpperLaserBladesImage,
        },
        {
            value: 3,
            label: "MONOWIRE",
            midSrc: armsUpperMonowireImage,
        },
        {
            value: 4,
            label: "RADIAL BLADES",
            midSrc: armsUpperRadialBladesImage,
        },
        {
            value: 5,
            label: "SOCOMS",
            src: armsUpperSOCOMsFrontImage,
            midSrc: armsUpperSOCOMsImage,
        },
        {
            value: 6,
            label: "STAFF",
            midSrc: armsUpperStaffImage,
        },
        {
            value: 7,
            label: "TARGETTING",
            midSrc: armsUpperTargettingImage,
        },
    ],
    rearMount: [
        {
            value: 0,
            label: "AMBER PHANTOM TRAILS",
            frontSrc: accAmberPhantomTrailsImage,
            backSrc: accAmberPhantomTrailsUnderImage,
        },
        {
            value: 1,
            label: "BLACK WITCH ROBES",
            frontSrc: accBlackWitchRobesImage,
            backSrc: accBlackWitchRobesUnderImage,
        },
        {
            value: 2,
            label: "CLOAK",
            frontSrc: accCloakImage,
            backSrc: accCloakUnderImage,
            isPremium: true,
            isLegendary: true,
        },
    ],
    accessory: [
        {
            value: 0,
            label: "LASER BLADES",
            frontSrc: glowLaserBladesImage,
        },
        {
            value: 1,
            label: "MONOWIRE - LOWER",
            frontSrc: glowLowerMonowireImage,
        },
        {
            value: 2,
            label: "MONOWIRE - UPPER",
            frontSrc: glowUpperMonowireImage,
        },
        {
            value: 3,
            label: "MONOWIRES - QUAD",
            frontSrc: glowQuadMonowiresImage,
        },
        {
            value: 4,
            label: "RADIAL BLADES - LOWER",
            frontSrc: glowLowerRadialBladesImage,
        },
        {
            value: 5,
            label: "RADIAL BLADES - UPPER",
            frontSrc: glowUpperRadialBladesImage,
        },
        {
            value: 6,
            label: "RADIAL BLADES - QUAD",
            frontSrc: glowQuadRadialBladesImage,
        },
        {
            value: 7,
            label: "RAIL RIFLE",
            foremostSrc: glowRailRifleImage,
        },
        {
            value: 8,
            label: "TARGETTING",
            frontSrc: glowTargettingImage,
        },
        {
            value: 9,
            label: "TRAILS",
            src: glowTrailsImage,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "WEAPONS",
            value: "WEAPONS",
        },
        {
            label: "GLOWS",
            value: "ACCESSORIES",
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "HEAD",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "LOWER ARMS",
            value: "weapon",
        },
        {
            label: "UPPER ARMS",
            value: "secondaryWeapon",
        },
        {
            label: "ACCESSORY",
            value: "rearMount",
        },
        {
            label: "GLOW 1",
            value: "accessory1",
            isGlow: true,
        },
        {
            label: "GLOW 2",
            value: "accessory2",
            isGlow: true,
        },
        {
            label: "GLOW 3",
            value: "accessory3",
            isGlow: true,
        },
        {
            label: "GLOW 4",
            value: "accessory4",
            isGlow: true,
        },
    ],
    labels: {
        "head": "HEAD",
        "chassis": "CHASSIS",
        "rearMount": "ACCESSORY",
        "weapon": "LOWER ARMS",
        "secondaryWeapon": "UPPER ARMS",
        "accessory1": "GLOW 1",
        "accessory2": "GLOW 2",
        "accessory3": "GLOW 3",
        "accessory4": "GLOW 4",
    },
};
