import { shadowTypes } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

const imagePath = `${BaseURL}/Mechs/Custom/Ishtara`;

// BODY
const bodyIshtaraImage = `${imagePath}/Chassis - Ishtara.png`;
const bodyOrbitalFrameImage = `${imagePath}/Chassis - Orbital Frame.png`;

// HEADS
const headIshtaraImage = `${imagePath}/heads/Head - Ishtara.png`;
const head8BallImage = `${imagePath}/heads/Head - 8-Ball.png`;
const headBlackspotImage = `${imagePath}/heads/Head - Blackspot.png`;
const headCalibanImage = `${imagePath}/heads/Head - Caliban.png`;
const headChadwickImage = `${imagePath}/heads/Head - Chadwick.png`;
const headCheddahImage = `${imagePath}/heads/Head - Cheddah.png`;
const headChimeraImage = `${imagePath}/heads/Head - Chimera.png`;
const headCyclopsImage = `${imagePath}/heads/Head - Cyclops.png`;
const headDungamImage = `${imagePath}/heads/Head - Dungam.png`;
const headEnkiduImage = `${imagePath}/heads/Head - Enkidu.png`;
const headGigaChadImage = `${imagePath}/heads/Head - GigaChad.png`;
const headHarrisonImage = `${imagePath}/heads/Head - Harrison.png`;
const headHornedImage = `${imagePath}/heads/Head - Horned.png`;
const headHornedHairImage = `${imagePath}/heads/Head - Horned Hair.png`;
const headHurricaneImage = `${imagePath}/heads/Head - Hurricane.png`;
const headKazuImage = `${imagePath}/heads/Head - Kazu.png`;
const headLaborerImage = `${imagePath}/heads/Head - Laborer.png`;
const headMagImage = `${imagePath}/heads/Head - Mag.png`;
const headMaliceImage = `${imagePath}/heads/Head - Malice.png`;
const headMecha1Image = `${imagePath}/heads/Head - Mecha 1.png`;
const headMecha2Image = `${imagePath}/heads/Head - Mecha 2.png`;
const headMecha3Image = `${imagePath}/heads/Head - Mecha 3.png`;
const headMecha4Image = `${imagePath}/heads/Head - Mecha 4.png`;
const headMecha5Image = `${imagePath}/heads/Head - Mecha 5.png`;
const headMecha6Image = `${imagePath}/heads/Head - Mecha 6.png`;
const headMecha7Image = `${imagePath}/heads/Head - Mecha 7.png`;
const headMecha8Image = `${imagePath}/heads/Head - Mecha 8.png`;
const headNaturalLawImage = `${imagePath}/heads/Head - Natural Law.png`;
const headNaturalLawHairImage = `${imagePath}/heads/Head - Natural Law Hair.png`;
const headNelsonFTImage = `${imagePath}/heads/Head - Flight Type Nelson.png`;
const headNemesisImage = `${imagePath}/heads/Head - Nemesis.png`;
const headRainImage = `${imagePath}/heads/Head - Rain.png`;
const headRainHairImage = `${imagePath}/heads/Head - Rain Hair.png`;
const headSkullImage = `${imagePath}/heads/Head - Skull.png`;
const headVanquisherImage = `${imagePath}/heads/Head - Vanquisher.png`;
const headViceroyImage = `${imagePath}/heads/Head - Viceroy.png`;
const headVisionaryImage = `${imagePath}/heads/Head - Visionary.png`;

// LEGS
const legsIshtaraImage = `${imagePath}/legs/Legs - Ishtara.png`;
const legsOrbitalFrameImage = `${imagePath}/legs/Legs - Orbital Frame.png`;
const legsOrbitalFrameBlankImage = `${imagePath}/legs/Legs - Orbital Frame Blank.png`;
const legsDashImage = `${imagePath}/legs/Legs - Dash.png`;
const legsSweetKicksImage = `${imagePath}/legs/Legs - Sweet Kicks.png`;

// WEAPONS
const weaponBladeImage = `${imagePath}/weapons/Weapon - Blade.png`;
const weaponClawImage = `${imagePath}/weapons/Weapon - Claw.png`;
const weaponDD288Image = `${imagePath}/weapons/Weapon - DD288 Fists.png`;
const weaponKnifeHandImage = `${imagePath}/weapons/Weapon - Knife Hand.png`;
const weaponMinigunsImage = `${imagePath}/weapons/Weapon - Miniguns.png`;
const weaponWristBeamsImage = `${imagePath}/weapons/Weapon - Wrist Beams.png`;

// ACCESSORY
const accessoryBladesImage = `${imagePath}/rear mounts/Rear Mount - Blades.png`;
const accessoryBladesUnderImage = `${imagePath}/rear mounts/Rear Mount Under - Blades.png`;
const accessoryMissilesImage = `${imagePath}/rear mounts/Rear Mount - Missiles.png`;
const accessoryMissilesUnderImage = `${imagePath}/rear mounts/Rear Mount Under - Missiles.png`;
const accessoryWingsImage = `${imagePath}/rear mounts/Rear Mount - Wings.png`;
const accessoryWingsOrbitalFrameImage = `${imagePath}/rear mounts/Rear Mount - Wings Orbital Frame.png`;
const accessoryBoosterImage = `${imagePath}/rear mounts/Rear Mount Under - Booster.png`;
const accessorySubWingsImage = `${imagePath}/rear mounts/Rear Mount Under - Sub-Wings.png`;
const accessoryTailImage = `${imagePath}/tails/Tail - Ishtara.png`;
const accessoryTailOrbitalFrameImage = `${imagePath}/tails/Tail - Orbital Frame.png`;

// GLOW
const glowKnifeHandImage = `${imagePath}/glows/Glow - Knife Hand.png`;
const glowWristBeamsImage = `${imagePath}/glows/Glow - Wrist Beams.png`;
const glowOrbitalImage = `${imagePath}/glows/Glow - Orbital.png`;
const glowOrbitalWingsImage = `${imagePath}/glows/Glow - Orbital + Wings.png`;
const glowWingsUnderImage = `${imagePath}/glows/Glow Under - Wings.png`;

const previewImage = `${imagePath}/preview.png`;
const restrictedPreviewImage = `${imagePath}/preview-restricted.png`;

const bipedLegsShadow = {
    size: shadowTypes.LARGE,
    bottom: "20px",
    right: "49px",
};

const originalArtCredit = {
    prefixText: "Commissioned by ",
    title: "HA-Zen",
    url: "https://discord.gg/JUF47G5",
};

export default {
    previewImg: previewImage,
    restrictedPreviewImg: restrictedPreviewImage,
    accessory2IsChassis: true,
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "0",
        "rearMountValue": "2",
        "weaponValue": "5",
        "secondaryWeaponValue": "0",
        "accessory1Value": "4",
        "accessory2Value": "NONE",
        "accessory3Value": "3",
        "accessory4Value": "0",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "ISHTAR",
            foremostSrc: headIshtaraImage,
        },
        {
            value: 1,
            label: "8-BALL",
            foremostSrc: head8BallImage,
        },
        {
            value: 2,
            label: "BLACKSPOT",
            foremostSrc: headBlackspotImage,
        },
        {
            value: 3,
            label: "CALIBAN",
            foremostSrc: headCalibanImage,
        },
        {
            value: 4,
            label: "CHADWICK",
            foremostSrc: headChadwickImage,
        },
        {
            value: 5,
            label: "CHEDDAH",
            foremostSrc: headCheddahImage,
        },
        {
            value: 6,
            label: "CHIMERA",
            foremostSrc: headChimeraImage,
        },
        {
            value: 7,
            label: "CYCLOPS",
            foremostSrc: headCyclopsImage,
        },
        {
            value: 8,
            label: "DUNGAM",
            foremostSrc: headDungamImage,
        },
        {
            value: 9,
            label: "ENKIDU",
            foremostSrc: headEnkiduImage,
        },
        {
            value: 10,
            label: "GIGACHAD",
            foremostSrc: headGigaChadImage,
        },
        {
            value: 11,
            label: "HARRISON",
            foremostSrc: headHarrisonImage,
        },
        {
            value: 12,
            label: "HORNED",
            foremostSrc: headHornedImage,
        },
        {
            value: 13,
            label: "HORNED HAIR",
            foremostSrc: headHornedHairImage,
        },
        {
            value: 14,
            label: "HURRICANE",
            foremostSrc: headHurricaneImage,
        },
        {
            value: 15,
            label: "KAZU",
            foremostSrc: headKazuImage,
        },
        {
            value: 16,
            label: "LABORER",
            foremostSrc: headLaborerImage,
        },
        {
            value: 17,
            label: "MAG",
            foremostSrc: headMagImage,
        },
        {
            value: 18,
            label: "MALICE",
            foremostSrc: headMaliceImage,
        },
        {
            value: 19,
            label: "MECHA 1",
            foremostSrc: headMecha1Image,
        },
        {
            value: 20,
            label: "MECHA 2",
            foremostSrc: headMecha2Image,
        },
        {
            value: 21,
            label: "MECHA 3",
            foremostSrc: headMecha3Image,
        },
        {
            value: 22,
            label: "MECHA 4",
            foremostSrc: headMecha4Image,
        },
        {
            value: 23,
            label: "MECHA 5",
            foremostSrc: headMecha5Image,
        },
        {
            value: 24,
            label: "MECHA 6",
            foremostSrc: headMecha6Image,
        },
        {
            value: 25,
            label: "MECHA 7",
            foremostSrc: headMecha7Image,
        },
        {
            value: 26,
            label: "MECHA 8",
            foremostSrc: headMecha8Image,
        },
        {
            value: 27,
            label: "NATURAL LAW",
            foremostSrc: headNaturalLawImage,
        },
        {
            value: 28,
            label: "NATURAL LAW HAIR",
            foremostSrc: headNaturalLawHairImage,
        },
        {
            value: 29,
            label: "NELSON FT",
            foremostSrc: headNelsonFTImage,
        },
        {
            value: 30,
            label: "NEMESIS",
            foremostSrc: headNemesisImage,
        },
        {
            value: 31,
            label: "RAIN TRANSFORMED",
            foremostSrc: headRainImage,
        },
        {
            value: 32,
            label: "RAIN TRANSFORMED HAIR",
            foremostSrc: headRainHairImage,
        },
        {
            value: 33,
            label: "SKULL",
            foremostSrc: headSkullImage,
        },
        {
            value: 34,
            label: "VANQUISHER",
            foremostSrc: headVanquisherImage,
        },
        {
            value: 35,
            label: "VICEROY",
            foremostSrc: headViceroyImage,
        },
        {
            value: 36,
            label: "VISIONARY",
            foremostSrc: headVisionaryImage,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "ISHTAR",
            src: bodyIshtaraImage,
            shadowDetails: bipedLegsShadow,
            originalArtCredit,
        },
        {
            value: 1,
            label: "ORBITAL FRAME",
            src: bodyOrbitalFrameImage,
            shadowDetails: bipedLegsShadow,
            originalArtCredit,
        },
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "BLADE",
                frontSrc: weaponBladeImage,
            },
            {
                value: 1,
                label: "CLAW",
                frontSrc: weaponClawImage,
            },
            {
                value: 2,
                label: "DD288",
                frontSrc: weaponDD288Image,
            },
            {
                value: 3,
                label: "KNIFE HAND",
                frontSrc: weaponKnifeHandImage,
            },
            {
                value: 4,
                label: "MINIGUNS",
                frontSrc: weaponMinigunsImage,
            },
            {
                value: 5,
                label: "WRIST BEAMS",
                frontSrc: weaponWristBeamsImage,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "ISHTAR",
            src: legsIshtaraImage,
        },
        {
            value: 1,
            label: "ORBITAL FRAME",
            src: legsOrbitalFrameImage,
        },
        {
            value: 2,
            label: "ORBITAL FRAME - DULL",
            src: legsOrbitalFrameBlankImage,
        },
        {
            value: 3,
            label: "DASH",
            src: legsDashImage,
        },
        {
            value: 4,
            label: "SWEET KICKS",
            src: legsSweetKicksImage,
        },
    ],
    rearMount: [
        {
            value: 0,
            label: "BLADES",
            frontSrc: accessoryBladesImage,
            backSrc: accessoryBladesUnderImage,
        },
        {
            value: 1,
            label: "MISSILES",
            frontSrc: accessoryMissilesImage,
            backSrc: accessoryMissilesUnderImage,
        },
        {
            value: 2,
            label: "WINGS",
            frontSrc: accessoryWingsImage,
            src: accessorySubWingsImage,
            backSrc: accessoryBoosterImage,
        },
        {
            value: 3,
            label: "WINGS - ORBITAL FRAME",
            frontSrc: accessoryWingsOrbitalFrameImage,
            src: accessorySubWingsImage,
            backSrc: accessoryBoosterImage,
        },
        {
            value: 4,
            label: "WINGS - NO BOOSTER",
            frontSrc: accessoryWingsImage,
            src: accessorySubWingsImage,
        },
        {
            value: 5,
            label: "WINGS - ORBITAL FRAME - NO BOOSTER",
            frontSrc: accessoryWingsOrbitalFrameImage,
            src: accessorySubWingsImage,
        },
    ],
    accessory: [
        {
            value: 0,
            label: "TAIL",
            backSrc: accessoryTailImage,
        },
        {
            value: 1,
            label: "TAIL - ORBITAL FRAME",
            backSrc: accessoryTailOrbitalFrameImage,
        },
        {
            value: 2,
            label: "KNIFE HAND - GLOW",
            frontSrc: glowKnifeHandImage,
        },
        {
            value: 3,
            label: "WRIST BEAMS - GLOW",
            frontSrc: glowWristBeamsImage,
        },
        {
            value: 4,
            label: "BOOSTERS - GLOW",
            backSrc: glowWingsUnderImage,
        },
        {
            value: 5,
            label: "ORBITAL FRAME - GLOW",
            frontSrc: glowOrbitalImage,
        },
        {
            value: 6,
            label: "ORBITAL FRAME + WINGS - GLOW",
            frontSrc: glowOrbitalWingsImage,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "GLOWS / ACCESSORY",
            value: "ACCESSORIES",
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "HEAD",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "WEAPONS",
            value: "weapon",
        },
        {
            label: "LEGS",
            value: "secondaryWeapon",
        },
        {
            label: "REAR MOUNT",
            value: "rearMount",
        },
        {
            label: "GLOW 1",
            value: "accessory1",
            isGlow: true,
        },
        {
            label: "GLOW 2",
            value: "accessory3",
            isGlow: true,
        },
        {
            label: "ACCESSORY",
            value: "accessory4",
        },
    ],
    labels: {
        "head": "HEAD",
        "chassis": "CHASSIS",
        "rearMount": "REAR MOUNT",
        "weapon": "WEAPONS",
        "secondaryWeapon": "LEGS",
        "accessory1": "GLOW 1",
        "accessory2": "UNUSED",
        "accessory3": "GLOW 2",
        "accessory4": "ACCESSORY",
    },
};
