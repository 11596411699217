import BaseURL from "./images/Hosting-BaseURL";
import { shadowTypes } from "../styles/UnitShadow";
import { contributorList } from "../Category/components/ArtCreditLink";

const imagePath = `${BaseURL}/Mechs/Custom/BDF-Klewang`;

// BODY
const bodyKlewangImage = `${imagePath}/Chassis.png`;
const bodyTyphoonBootsImage = `${imagePath}/Chassis - Typhoon Boots.png`;

// DECALS
const decalSkullsImage = `${imagePath}/decals/Decal - Skulls.png`;

// ARMS
// LEFT
const armsLeftBladeImage = `${imagePath}/arms/left/Left Arm - Blade.png`;
const armsLeftHandImage = `${imagePath}/arms/left/Left Arm - Hand.png`;
const armsLeftCargoCrane1Image = `${imagePath}/arms/left/Left Arm - Cargo Crane 1.png`;
const armsLeftCargoCrane2Image = `${imagePath}/arms/left/Left Arm - Cargo Crane 2.png`;
const armsLeftClawImage = `${imagePath}/arms/left/Left Arm - Claw.png`;
const armsLeftMantisDD288Image = `${imagePath}/arms/left/Left Arm - Mantis DD288.png`;
const armsLeftMantisDD288HandImage = `${imagePath}/arms/left/Left Arm - Mantis DD288 Hand.png`;

// RIGHT
const armsRightBladeImage = `${imagePath}/arms/right/Rightt Arm - Blade.png`;
const armsRightHandImage = `${imagePath}/arms/right/Rightt Arm - Hand.png`;
const armsRightCargoCrane1Image = `${imagePath}/arms/right/Rightt Arm - Cargo Crane 1.png`;
const armsRightCargoCrane2Image = `${imagePath}/arms/right/Rightt Arm - Cargo Crane 2.png`;
const armsRightClawImage = `${imagePath}/arms/right/Rightt Arm - Claw.png`;
const armsRightMantisDD288Image = `${imagePath}/arms/right/Right Arm - Mantis DD288.png`;

// GLOW
const glowKlewangHeadImage = `${imagePath}/glows/Glow - Klewang Head.png`;
const glowTyphoonBootsImage = `${imagePath}/glows/Glow 1 - Typhoon Boots.png`;
const glowLeftClawImage = `${imagePath}/glows/Glow - Left Claw.png`;
const glowRightClawImage = `${imagePath}/glows/Glow - Right Claw.png`;
const glowDualClawsImage = `${imagePath}/glows/Glow - Dual Claws.png`;
const glowLeftMantisDD288Image = `${imagePath}/glows/Glow - Left Mantis DD288.png`;
const glowRightMantisDD288Image = `${imagePath}/glows/Glow - Right Mantis DD288.png`;
const glowDualMantisDD288Image = `${imagePath}/glows/Glow - Dual Mantis DD288.png`;

const previewImage = `${imagePath}/preview.png`;
const restrictedPreviewImage = `${imagePath}/preview-restricted.png`;

const core1Image = `${imagePath}/Klewang_by_gummies139.png`;

const bipedLegsShadow = {
    size: shadowTypes.SUPREMELY_LARGE,
    bottom: "59px",
    right: "-14px",
};

export default {
    isMegadeusPlus: true,
    rearMountsAreChassis: true,
    previewImg: previewImage,
    restrictedPreviewImg: restrictedPreviewImage,
    coreImg: [
        {
            src: core1Image,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "Gummies139",
                url: "https://discord.gg/JUF47G5",
            },
        },
    ],
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "0",
        "rearMountValue": "NONE",
        "weaponValue": "0",
        "secondaryWeaponValue": "0",
        "accessory1Value": "0",
        "accessory2Value": "NONE",
        "accessory3Value": "NONE",
        "accessory4Value": "NONE",
        "stanceValue": "0",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "SKULLS",
            src: decalSkullsImage,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "KLEWANG",
            src: bodyKlewangImage,
            shadowDetails: bipedLegsShadow,
            originalArtCredit: contributorList.BDF_MFR,
        },
        {
            value: 1,
            label: "TYPHOON BOOTS",
            src: bodyTyphoonBootsImage,
            shadowDetails: bipedLegsShadow,
            originalArtCredit: contributorList.BDF_MFR,
        },
    ],
    weapon: {
        "0": [
            {
                value: 0,
                label: "BLADE",
                src: armsLeftBladeImage,
            },
            {
                value: 1,
                label: "HAND",
                src: armsLeftHandImage,
            },
            {
                value: 2,
                label: "CARGO CRANE 1",
                src: armsLeftCargoCrane1Image,
            },
            {
                value: 3,
                label: "CARGO CRANE 2",
                src: armsLeftCargoCrane2Image,
            },
            {
                value: 4,
                label: "CLAW",
                src: armsLeftClawImage,
            },
            {
                value: 5,
                label: "MANTIS DD288",
                src: armsLeftMantisDD288Image,
            },
            {
                value: 6,
                label: "MANTIS DD288 + HAND",
                src: armsLeftMantisDD288HandImage,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "BLADE",
            src: armsRightBladeImage,
        },
        {
            value: 1,
            label: "HAND",
            src: armsRightHandImage,
        },
        {
            value: 2,
            label: "CARGO CRANE 1",
            src: armsRightCargoCrane1Image,
        },
        {
            value: 3,
            label: "CARGO CRANE 2",
            src: armsRightCargoCrane2Image,
        },
        {
            value: 4,
            label: "CLAW",
            src: armsRightClawImage,
        },
        {
            value: 5,
            label: "MANTIS DD288",
            src: armsRightMantisDD288Image,
        },
    ],
    rearMount: [],
    accessory: [
        {
            value: 0,
            label: "KLEWANG HEAD",
            src: glowKlewangHeadImage,
        },
        {
            value: 1,
            label: "TYPHOON BOOTS",
            src: glowTyphoonBootsImage,
        },
        {
            value: 2,
            label: "LEFT CLAW",
            frontSrc: glowLeftClawImage,
        },
        {
            value: 3,
            label: "RIGHT CLAW",
            frontSrc: glowRightClawImage,
        },
        {
            value: 4,
            label: "DUAL CLAWS",
            frontSrc: glowDualClawsImage,
        },
        {
            value: 5,
            label: "LEFT MANTIS DD288",
            frontSrc: glowLeftMantisDD288Image,
        },
        {
            value: 6,
            label: "RIGHT MANTIS DD288",
            frontSrc: glowRightMantisDD288Image,
        },
        {
            value: 7,
            label: "DUAL MANTIS DD288",
            frontSrc: glowDualMantisDD288Image,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "GLOWS",
            value: "ACCESSORIES",
            isGlow: true,
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "DECAL",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "L ARM",
            value: "weapon",
        },
        {
            label: "R ARM",
            value: "secondaryWeapon",
        },
        {
            label: "GLOW 1",
            value: "accessory1",
            isGlow: true,
        },
        {
            label: "GLOW 2",
            value: "accessory2",
            isGlow: true,
        },
        {
            label: "GLOW 3",
            value: "accessory3",
            isGlow: true,
        },
        {
            label: "GLOW 4",
            value: "accessory4",
            isGlow: true,
        },
    ],
    labels: {
        "head": "DECAL",
        "chassis": "CHASSIS",
        "rearMount": "EMPTY",
        "weapon": "L ARM",
        "secondaryWeapon": "R ARM",
        "accessory1": "GLOW 1",
        "accessory2": "GLOW 2",
        "accessory3": "GLOW 3",
        "accessory4": "GLOW 4",
    },
};
