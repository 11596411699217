import { shadowTypes } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

const imagePath = ` ${BaseURL}/Mechs/HA/Enkidu`;

// BODY
const bodyEnkiduImage = `${imagePath}/Enkidu Chassis.png`;
const leftArmDownImage = `${imagePath}/Arms - Down.png`;
const leftArmUpImage = `${imagePath}/Arms - Cross Stance.png`;

// HEADS
const headEnkiduImage = `${imagePath}/heads/Head - Enkidu.png`;
const headKazuImage = `${imagePath}/heads/Head - Kazu.png`;
// PREMIUM
const headCalibanImage = `${imagePath}/heads/premium/Head - Caliban.png`;
const headChimeraImage = `${imagePath}/heads/premium/Head - Chimera.png`;
const headCyclopsImage = `${imagePath}/heads/premium/Head - Cyclops.png`;
const headLaborerImage = `${imagePath}/heads/premium/Head - Laborer.png`;
const headMagImage = `${imagePath}/heads/premium/Head - Mag.png`;
const headMaliceImage = `${imagePath}/heads/premium/Head - Malice.png`;
const headNapoleonImage = `${imagePath}/heads/premium/Head - Napoleon.png`;
const headHornedImage = `${imagePath}/heads/premium/Head - Horned.png`;
const headHornedHairImage = `${imagePath}/heads/premium/Head - Horned Hair.png`;
const headNaturalLawImage = `${imagePath}/heads/premium/Head - Natural Law.png`;
const headNaturalLawHairImage = `${imagePath}/heads/premium/Head - Natural Law Hair.png`;
const headRainImage = `${imagePath}/heads/premium/Head - Rain.png`;
const headRainHairImage = `${imagePath}/heads/premium/Head - Rain Hair.png`;
const headScopehoundImage = `${imagePath}/heads/premium/Head - Scopedog.png`;
const headTokugawaImage = `${imagePath}/heads/premium/Head - Tokugawa.png`;

// GLOWS
const glowEnkiduHeadImage = `${imagePath}/glows/Glow - Enkidu Head.png`;
const glowTokugawaHeadImage = `${imagePath}/glows/Glow - Tokugawa Head.png`;
const glowKnifeReverseImage = `${imagePath}/glows/Glow - Knife - Reverse Grip.png`;
const glowKnivesImage = `${imagePath}/glows/Glow - Knives.png`;
const glowKnifeLeftImage = `${imagePath}/glows/Glow - Left Knife.png`;
const glowWhipclawLeftImage = `${imagePath}/glows/Glow - Whipclaw Left.png`;
const glowWhipclawLeftDownImage = `${imagePath}/glows/Glow - Whipclaw Left Down.png`;
const glowWhipclawLeftDownSubImage = `${imagePath}/glows/Glow - Whipclaw Left Down sublayer.png`;
const glowWhipclawRightImage = `${imagePath}/glows/Glow - Whipclaw Right.png`;
const glowWhipclawsDownImage = `${imagePath}/glows/Glow - Whipclaws - Down.png`;
const glowWhipclawsCrossStanceImage = `${imagePath}/glows/Glow - Whipclaws - Cross Stance.png`;
const glowHCBLeftImage = `${imagePath}/glows/Glow - Left HCB.png`;
const glowHCBRightImage = `${imagePath}/glows/Glow - Right HCB.png`;
const glowHCBsImage = `${imagePath}/glows/Glow - Dual HCB.png`;
const glowChainAxeLeftImage = `${imagePath}/glows/Glow - Chain Axe.png`;

// ACCESSORY
const synthMuscleComboDownImage = `${imagePath}/accessories/Accessory - Synthetic Muscle Netting - Down.png`;
const synthMuscleComboUpImage = `${imagePath}/accessories/Accessory - Synthetic Muscle Netting - Cross Stance.png`;
const totalStrengthSuiteDownImage = `${imagePath}/accessories/Accessory - Total Strength Suite - Down.png`;
const totalStrengthSuiteUpImage = `${imagePath}/accessories/Accessory - Total Strength Suite - Cross Stance.png`;
const tubesDownImage = `${imagePath}/accessories/Accessory - Tubes - Down.png`;
const tubesUpImage = `${imagePath}/accessories/Accessory - Tubes - Cross Stance.png`;
const synthMuscleHeadComboDownImage = `${imagePath}/accessories/Accessory - Synthetic Muscle Netting - Down Head.png`;
const synthMuscleHeadComboUpImage = `${imagePath}/accessories/Accessory - Synthetic Muscle Netting - Cross Stance Head.png`;
const tubesHeadDownImage = `${imagePath}/accessories/Accessory - Tubes - Down Head.png`;
const tubesHeadUpImage = `${imagePath}/accessories/Accessory - Tubes - Cross Stance Head.png`;

// 1H RIGHT
const gripRight1HImage = `${imagePath}/weapons/right/Right Hand - Grip.png`;
const fist1Right1HImage = `${imagePath}/weapons/right/Right Hand - Fist 1.png`;
const fist2Right1HImage = `${imagePath}/weapons/right/Right Hand - Fist 2.png`;
const whipclawRight1HImage = `${imagePath}/weapons/right/Right Hand - Whipclaw.png`;
const combatDrillRight1HImage = `${imagePath}/weapons/right/Right Weapon - Combat Drill.png`;
const hcbRight1HImage = `${imagePath}/weapons/right/Right Weapon - HCB.png`;
const knifeRight1HImage = `${imagePath}/weapons/right/Right Weapon - Knife.png`;
const knivesRight1HImage = `${imagePath}/weapons/right/Right Weapon - Knives.png`;
const knifeReverseRight1HImage = `${imagePath}/weapons/right/Right Weapon - Knife Reverse Grip.png`;
const axeRight1HImage = `${imagePath}/weapons/right/Right Weapon - Axe.png`;
// LEGENDARY
const rotaryCannonRight1HImage = `${imagePath}/weapons/right/Right Weapon - Rotary Cannon.png`;
const rotaryCannonSubRight1HImage = `${imagePath}/weapons/right/Right Weapon - Rotary Cannon Sublayer.png`;

// 1H LEFT
// UP
const gripLeft1HImage = `${imagePath}/weapons/left/Left Hand - Grip.png`;
const fistLeft1HImage = `${imagePath}/weapons/left/Left Hand - Fist.png`;
const whipclawLeft1HImage = `${imagePath}/weapons/left/Left Hand - Whipclaw.png`;
const knifeLeft1HImage = `${imagePath}/weapons/left/Left Weapon - Knife.png`;
const bladeLeft1HImage = `${imagePath}/weapons/left/Left Weapon - Blade.png`;
const nanocarbonBladeLeft1HImage = `${imagePath}/weapons/left/Left Weapon - Nanocarbon Blade.png`;
const axeLeft1HImage = `${imagePath}/weapons/left/Left Weapon - Axe.png`;
const chainAxeLeft1HImage = `${imagePath}/weapons/left/Left Weapon - Chain Axe.png`;
const hcbLeft1HImage = `${imagePath}/weapons/left/Left Weapon - HCB.png`;
// DOWN
const whipclawDownLeft1HImage = `${imagePath}/weapons/left/Left Hand - Down.png`;
// LEGENDARY
const rotaryCannonLeft1HImage = `${imagePath}/weapons/left/Left Weapon - Rotary Cannon.png`;
const rotaryCannonSubLeft1HImage = `${imagePath}/weapons/left/Left Weapon - Rotary Cannon Sublayer.png`;
const rotaryCannonDual1HImage = `${imagePath}/weapons/Weapon - Dual Rotary Cannons.png`;
const rotaryCannonSubDual1HImage = `${imagePath}/weapons/Weapon - Dual Rotary Cannons Sublayer.png`;

const coreImage = `${imagePath}/CharlieMerlynn - Enkidu.png`;
const core2Image = `${imagePath}/Enkidu-core-Garya.png`;
const core3Image = `${imagePath}/Enkidu-2-core-Garya.png`;

const previewImage = `${BaseURL}/Mechs/HA/Enkidu/preview.png`;

const bipedLegsShadow = {
    size: shadowTypes.SUPREMELY_LARGE,
    bottom: "14px",
    right: "-4px",
};

export default {
    previewImg: previewImage,
    rearMountsAreChassis: true,
    rearMountsAreChassisSpecial: "stanceValue",
    hasMultipleStances: true,
    removeNone: {
        secondaryWeapon: true,
    },
    coreImg: [
        {
            src: coreImage,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "CharlieMerlynn",
                url: "https://discord.gg/JUF47G5",
            },
        },
        {
            src: core2Image,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "Garya",
                url: "https://discord.gg/JUF47G5",
            },
        },
        {
            src: core3Image,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "Garya",
                url: "https://discord.gg/JUF47G5",
            },
        },
    ],
    defaultValues: `{
        "chassisValue": "1",
        "headValue": "0",
        "rearMountValue": "NONE",
        "weaponValue": "2",
        "secondaryWeaponValue": "6",
        "accessory1Value": "0",
        "accessory2Value": "9",
        "accessory3Value": "15",
        "accessory4Value": "NONE",
        "stanceValue": "1",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "ENKIDU",
            src: headEnkiduImage,
        },
        {
            value: 1,
            label: "KAZU",
            src: headKazuImage,
        },
        {
            value: 2,
            label: "CALIBAN",
            src: headCalibanImage,
            isPremium: true,
        },
        {
            value: 3,
            label: "CHIMERA",
            src: headChimeraImage,
            isPremium: true,
        },
        {
            value: 4,
            label: "CYCLOPS",
            src: headCyclopsImage,
            isPremium: true,
        },
        {
            value: 5,
            label: "LABORER",
            src: headLaborerImage,
            isPremium: true,
        },
        {
            value: 6,
            label: "MAG",
            src: headMagImage,
            isPremium: true,
        },
        {
            value: 7,
            label: "MALICE",
            src: headMaliceImage,
            isPremium: true,
        },
        {
            value: 8,
            label: "NAPOLEON",
            src: headNapoleonImage,
            isPremium: true,
        },
        {
            value: 9,
            label: "NATURAL LAW",
            src: headNaturalLawImage,
            isPremium: true,
        },
        {
            value: 10,
            label: "NATURAL LAW HAIR",
            src: headNaturalLawHairImage,
            isPremium: true,
        },
        {
            value: 11,
            label: "RAIN",
            src: headRainImage,
            isPremium: true,
        },
        {
            value: 12,
            label: "RAIN HAIR",
            src: headRainHairImage,
            isPremium: true,
        },
        {
            value: 13,
            label: "HORNED",
            src: headHornedImage,
            isPremium: true,
        },
        {
            value: 14,
            label: "HORNED HAIR",
            src: headHornedHairImage,
            isPremium: true,
        },
        {
            value: 15,
            label: "SCOPEHOUND",
            src: headScopehoundImage,
            isPremium: true,
        },
        {
            value: 16,
            label: "TOKUGAWA",
            src: headTokugawaImage,
            isPremium: true,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "ARMS DOWN",
            src: bodyEnkiduImage,
            frontSrc: leftArmDownImage,
            shadowDetails: bipedLegsShadow,
        },
        {
            value: 1,
            label: "ARMS CROSSED",
            src: bodyEnkiduImage,
            frontSrc: leftArmUpImage,
            shadowDetails: bipedLegsShadow,
        },
    ],
    weapon: {
        "0": [
            {
                value: 0,
                label: "WHIPCLAW",
                frontSrc: whipclawDownLeft1HImage,
            },
            {
                value: 1,
                label: "ROTARY CANNON",
                foremostSrc: rotaryCannonLeft1HImage,
                backSrc: rotaryCannonSubLeft1HImage,
                isPremium: true,
                isLegendary: true,
            },
            {
                value: 2,
                label: "DUAL ROTARY CANNONS",
                foremostSrc: rotaryCannonDual1HImage,
                backSrc: rotaryCannonSubDual1HImage,
                isPremium: true,
                isLegendary: true,
            },
        ],
        "1": [
            {
                value: 0,
                label: "FIST",
                frontSrc: fistLeft1HImage,
            },
            {
                value: 1,
                label: "KNIFE",
                frontSrc: gripLeft1HImage,
                foremostSrc: knifeLeft1HImage,
            },
            {
                value: 2,
                label: "WHIPCLAW",
                frontSrc: whipclawLeft1HImage,
            },
            {
                value: 3,
                label: "BLADE",
                frontSrc: gripLeft1HImage,
                foremostSrc: bladeLeft1HImage,
            },
            {
                value: 4,
                label: "NANOCARBON BLADE",
                frontSrc: gripLeft1HImage,
                foremostSrc: nanocarbonBladeLeft1HImage,
            },
            {
                value: 5,
                label: "HCB",
                frontSrc: gripLeft1HImage,
                foremostSrc: hcbLeft1HImage,
            },
            {
                value: 6,
                label: "AXE",
                frontSrc: gripLeft1HImage,
                foremostSrc: axeLeft1HImage,
            },
            {
                value: 7,
                label: "CHAIN AXE",
                frontSrc: gripLeft1HImage,
                foremostSrc: chainAxeLeft1HImage,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "FIST 1",
            foremostSrc: fist1Right1HImage,
        },
        {
            value: 1,
            label: "FIST 2",
            foremostSrc: fist2Right1HImage,
        },
        {
            value: 2,
            label: "KNIFE",
            frontSrc: gripRight1HImage,
            foremostSrc: knifeRight1HImage,
        },
        {
            value: 3,
            label: "KNIFE REVERSE GRIP",
            frontSrc: gripRight1HImage,
            foremostSrc: knifeReverseRight1HImage,
        },
        {
            value: 4,
            label: "KNIVES",
            frontSrc: gripRight1HImage,
            foremostSrc: knivesRight1HImage,
        },
        {
            value: 5,
            label: "COMBAT DRILL",
            foremostSrc: combatDrillRight1HImage,
        },
        {
            value: 6,
            label: "WHIPCLAW",
            foremostSrc: whipclawRight1HImage,
        },
        {
            value: 7,
            label: "HCB",
            frontSrc: gripRight1HImage,
            foremostSrc: hcbRight1HImage,
        },
        {
            value: 8,
            label: "AXE",
            foremostSrc: axeRight1HImage,
            frontSrc: gripRight1HImage,
        },
        {
            value: 9,
            label: "ROTARY CANNON",
            foremostSrc: rotaryCannonRight1HImage,
            backSrc: rotaryCannonSubRight1HImage,
            isPremium: true,
            isLegendary: true,
        },
    ],
    rearMount: [],
    accessory: [
        {
            value: 0,
            label: "ENKIDU HEAD",
            frontSrc: glowEnkiduHeadImage,
        },
        {
            value: 1,
            label: "TOKUGAWA HEAD",
            foremostSrc: glowTokugawaHeadImage,
        },
        {
            value: 2,
            label: "KNIFE LEFT",
            foremostSrc: glowKnifeLeftImage,
        },
        {
            value: 3,
            label: "KNIFE REVERSE GRIP",
            foremostSrc: glowKnifeReverseImage,
        },
        {
            value: 4,
            label: "KNIVES",
            foremostSrc: glowKnivesImage,
        },
        {
            value: 5,
            label: "WHIPCLAW LEFT - CROSS STANCE",
            foremostSrc: glowWhipclawLeftImage,
        },
        {
            value: 6,
            label: "WHIPCLAW LEFT - DOWN",
            foremostSrc: glowWhipclawLeftDownImage,
            backSrc: glowWhipclawLeftDownSubImage,
        },
        {
            value: 7,
            label: "WHIPCLAW RIGHT",
            frontSrc: glowWhipclawRightImage,
        },
        {
            value: 8,
            label: "WHIPCLAWS - DOWN",
            frontSrc: glowWhipclawsDownImage,
        },
        {
            value: 9,
            label: "WHIPCLAWS - CROSS STANCE",
            foremostSrc: glowWhipclawsCrossStanceImage,
        },
        {
            value: 10,
            label: "HCB LEFT",
            foremostSrc: glowHCBLeftImage,
        },
        {
            value: 11,
            label: "HCB RIGHT",
            foremostSrc: glowHCBRightImage,
        },
        {
            value: 12,
            label: "DUAL HCB",
            foremostSrc: glowHCBsImage,
        },
        {
            value: 13,
            label: "CHAIN AXE LEFT",
            foremostSrc: glowChainAxeLeftImage,
        },
        {
            value: 14,
            label: "TUBES - DOWN",
            frontSrc: tubesDownImage,
        },
        {
            value: 15,
            label: "TUBES - CROSS STANCE",
            frontSrc: tubesUpImage,
        },
        {
            value: 16,
            label: "TUBES + HEAD - DOWN",
            frontSrc: tubesHeadDownImage,
        },
        {
            value: 17,
            label: "TUBES + HEAD - CROSS STANCE",
            frontSrc: tubesHeadUpImage,
        },
        {
            value: 18,
            label: "SYNTHETIC MUSCLE NETTING - DOWN",
            frontSrc: synthMuscleComboDownImage,
        },
        {
            value: 19,
            label: "SYNTHETIC MUSCLE NETTING - CROSS STANCE",
            frontSrc: synthMuscleComboUpImage,
        },
        {
            value: 20,
            label: "SYNTHETIC MUSCLE NETTING + HEAD - DOWN",
            frontSrc: synthMuscleHeadComboDownImage,
        },
        {
            value: 21,
            label: "SYNTHETIC MUSCLE NETTING + HEAD - CROSS STANCE",
            frontSrc: synthMuscleHeadComboUpImage,
        },
        {
            value: 22,
            label: "TOTAL STRENGTH SUITE - DOWN",
            frontSrc: totalStrengthSuiteDownImage,
        },
        {
            value: 23,
            label: "TOTAL STRENGTH SUITE - CROSS STANCE",
            frontSrc: totalStrengthSuiteUpImage,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "WEAPONS",
            value: "WEAPONS",
        },
        {
            label: "GLOWS",
            value: "ACCESSORIES",
            isGlow: true,
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "HEAD",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "LEFT WEAPON",
            value: "weapon",
        },
        {
            label: "RIGHT WEAPON",
            value: "secondaryWeapon",
        },
        {
            label: "GLOW 1",
            value: "accessory1",
            isGlow: true,
        },
        {
            label: "GLOW 2",
            value: "accessory2",
            isGlow: true,
        },
        {
            label: "GLOW 3",
            value: "accessory3",
            isGlow: true,
        },
        {
            label: "GLOW 4",
            value: "accessory4",
            isGlow: true,
        },
    ],
    labels: {
        "head": "HEAD",
        "chassis": "STANCE",
        "rearMount": "REAR MOUNT",
        "weapon": "LEFT WEAPON",
        "secondaryWeapon": "RIGHT WEAPON",
        "accessory1": "GLOW 1",
        "accessory2": "GLOW 2",
        "accessory3": "GLOW 3",
        "accessory4": "GLOW 4",
    },
};
