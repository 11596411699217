import BaseURL from "./images/Hosting-BaseURL";
import { shadowTypes } from "../styles/UnitShadow";

const imagePath = `${BaseURL}/Mechs/Custom/IPS-N-Crab`;

// BODY
const bodyImage = `${imagePath}/Chassis.png`;

// HEADS
const headHardshellImage = `${imagePath}/heads/Head_ Hardshell.png`;
const headHermitImage = `${imagePath}/heads/Head_ Hermit.png`;
const headSoftshell1Image = `${imagePath}/heads/Head_ Softshell 1.png`;
const headSoftshell2Image = `${imagePath}/heads/Head_ Softshell 2.png`;
const headSoveriegnImage = `${imagePath}/heads/Head_ Soveriegn.png`;

// WEAPONS
// LEFT
const weaponLeftChainsawImage = `${imagePath}/weapons/left/Weapons Left_Chainsaw.png`;
const weaponLeftClawImage = `${imagePath}/weapons/left/Weapons Left_Claw.png`;
const weaponLeftDaisyCutterImage = `${imagePath}/weapons/left/Weapons Left_Daisy Cutter.png`;
const weaponLeftGaussCannonImage = `${imagePath}/weapons/left/Weapons Left_Gauss Cannon.png`;
const weaponLeftGigaDrillImage = `${imagePath}/weapons/left/Weapons Left_Giga Drill.png`;
const weaponLeftRifleImage = `${imagePath}/weapons/left/Weapons Left_Rifle.png`;
const weaponLeftTorchImage = `${imagePath}/weapons/left/Weapons Left_Torch.png`;

// RIGHT
const weaponRightChainsawImage = `${imagePath}/weapons/right/Weapons Righ_Chainsaw.png`;
const weaponRightClawImage = `${imagePath}/weapons/right/Weapons Righ_Claw.png`;
const weaponRightGaussCannonImage = `${imagePath}/weapons/right/Weapons Righ_Gauss Cannon.png`;
const weaponRightGigaDrillImage = `${imagePath}/weapons/right/Weapons Righ_Giga Drill.png`;
const weaponRightRifleImage = `${imagePath}/weapons/right/Weapons Righ_Rifle.png`;
const weaponRightTorchImage = `${imagePath}/weapons/right/Weapons Righ_Torch.png`;

// REAR MOUNT
const rearCommsArrayLeftImage = `${imagePath}/rear/Rear Mount Back_ Comms Array Left.png`;
const rearCommsArrayRightImage = `${imagePath}/rear/Rear Mount Back_ Comms Array Right.png`;
const rearLotsaMissilesLeftImage = `${imagePath}/rear/Rear Mount Back_ Lotsa Missiles Left.png`;
const rearLotsaMissilesRightImage = `${imagePath}/rear/Rear Mount Back_ Lotsa Missiles Right.png`;
const rearMicroMissilesLeftImage = `${imagePath}/rear/Rear Mount Back_ MicroMissiles Left.png`;
const rearMicroMissilesRightImage = `${imagePath}/rear/Rear Mount Back_ MicroMissiles Right.png`;
const rearMissileBoxLeftImage = `${imagePath}/rear/Rear Mount Back_ Missile Box Left.png`;
const rearMissileBoxRightImage = `${imagePath}/rear/Rear Mount Back_ Missile Box Right.png`;
const rearMissilesLeftImage = `${imagePath}/rear/Rear Mount Back_ Missiles Left.png`;
const rearMissilesRightImage = `${imagePath}/rear/Rear Mount Back_ Missiles Right.png`;
const rearParticleBlasterLeftImage = `${imagePath}/rear/Rear Mount Back_ Particle Blaster Left.png`;
const rearParticleBlasterRightImage = `${imagePath}/rear/Rear Mount Back_ Particle Blaster Right.png`;
const rearRocketsLeftImage = `${imagePath}/rear/Rear Mount Front_ Rockets Left.png`;
const rearRocketsRightImage = `${imagePath}/rear/Rear Mount Front_ Rockets Right.png`;
const rearTurretLeftImage = `${imagePath}/rear/Rear Mount Front_ Turret Left.png`;
const rearTurretRightImage = `${imagePath}/rear/Rear Mount Front_ Turret Right.png`;

// ACCESSORY
const accCargoBasketLeftEmptyImage = `${imagePath}/accessory/Accessories Back_ Cargo Basket Left Empty.png`;
const accCargoBasketLeftLogsImage = `${imagePath}/accessory/Accessories Back_ Cargo Basket Left Logs.png`;
const accCargoBasketLeftSuppliesImage = `${imagePath}/accessory/Accessories Back_ Cargo Basket Left Supplies.png`;

// GLOWS
const glowGaussCannonLeftImage = `${imagePath}/glows/Accessories Mid_ Gauss Cannon Glow Left.png`;
const glowGaussCannonRightImage = `${imagePath}/glows/Accessories Mid_ Gauss Cannon Glow Right.png`;
const glowParticleBlasterLeftImage = `${imagePath}/glows/Accessories Front_Particle Blaster Glow Left.png`;
const glowParticleBlasterRightImage = `${imagePath}/glows/Accessories Front_Particle Blaster Glow Right.png`;
const glowTorchLeftImage = `${imagePath}/glows/Accessories Mid_ Torch Glow Left.png`;
const glowTorchRightImage = `${imagePath}/glows/Accessories Mid_ Torch Glow Right.png`;

const previewImage = `${imagePath}/preview.png`;

const bipedLegsShadow = {
    size: shadowTypes.SUPREMELY_LARGE,
    bottom: "-6px",
    right: "-21px",
};

export default {
    previewImg: previewImage,
    isMegadeus: true,
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "0",
        "rearMountValue": "1",
        "weaponValue": "1",
        "secondaryWeaponValue": "1",
        "accessory1Value": "7",
        "accessory2Value": "NONE",
        "accessory3Value": "27",
        "accessory4Value": "25",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "HARDSHELL",
            src: headHardshellImage,
        },
        {
            value: 1,
            label: "HERMIT",
            src: headHermitImage,
        },
        {
            value: 2,
            label: "SOFTSHELL 1",
            src: headSoftshell1Image,
        },
        {
            value: 3,
            label: "SOFTSHELL 2",
            src: headSoftshell2Image,
        },
        {
            value: 4,
            label: "SOVERIEGN",
            src: headSoveriegnImage,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "IPS-N CRAB",
            src: bodyImage,
            shadowDetails: bipedLegsShadow,
            originalArtCredit: {
                prefixText: "Created by Pinkhyena, commissioned by Wander (as seen on Hearth-7), original design by ",
                title: "Mythril",
                url: "https://bsky.app/profile/mithryl.bsky.social",
            },
        },
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "CHAINSAW",
                src: weaponLeftChainsawImage,
            },
            {
                value: 1,
                label: "CLAW",
                src: weaponLeftClawImage,
            },
            {
                value: 2,
                label: "DAISY CUTTER",
                src: weaponLeftDaisyCutterImage,
            },
            {
                value: 3,
                label: "GAUSS CANNON",
                src: weaponLeftGaussCannonImage,
            },
            {
                value: 4,
                label: "GIGA DRILL",
                src: weaponLeftGigaDrillImage,
            },
            {
                value: 5,
                label: "RIFLE",
                src: weaponLeftRifleImage,
            },
            {
                value: 6,
                label: "TORCH",
                src: weaponLeftTorchImage,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "CHAINSAW",
            src: weaponRightChainsawImage,
        },
        {
            value: 1,
            label: "CLAW",
            src: weaponRightClawImage,
        },
        {
            value: 2,
            label: "GAUSS CANNON",
            src: weaponRightGaussCannonImage,
        },
        {
            value: 3,
            label: "GIGA DRILL",
            src: weaponRightGigaDrillImage,
        },
        {
            value: 4,
            label: "RIFLE",
            src: weaponRightRifleImage,
        },
        {
            value: 5,
            label: "TORCH",
            src: weaponRightTorchImage,
        },
    ],
    rearMount: [
        {
            value: 0,
            label: "CARGO BASKET LEFT - EMPTY",
            src: accCargoBasketLeftEmptyImage,
        },
        {
            value: 1,
            label: "CARGO BASKET LEFT - LOGS",
            src: accCargoBasketLeftLogsImage,
        },
        {
            value: 2,
            label: "CARGO BASKET LEFT - SUPPLIES",
            src: accCargoBasketLeftSuppliesImage,
        },
    ],
    accessory: [
        {
            value: 0,
            label: "GLOW - GAUSS CANNON LEFT",
            frontSrc: glowGaussCannonLeftImage,
        },
        {
            value: 1,
            label: "GLOW - GAUSS CANNON RIGHT",
            frontSrc: glowGaussCannonRightImage,
        },
        {
            value: 2,
            label: "GLOW - PARTICLE BLASTER LEFT",
            frontSrc: glowParticleBlasterLeftImage,
        },
        {
            value: 3,
            label: "GLOW - PARTICLE BLASTER RIGHT",
            frontSrc: glowParticleBlasterRightImage,
        },
        {
            value: 4,
            label: "GLOW - TORCH LEFT",
            frontSrc: glowTorchLeftImage,
        },
        {
            value: 5,
            label: "GLOW - TORCH RIGHT",
            frontSrc: glowTorchRightImage,
        },
        {
            value: 6,
            label: "REAR - COMMS ARRAY LEFT",
            src: rearCommsArrayLeftImage,
        },
        {
            value: 7,
            label: "REAR - COMMS ARRAY RIGHT",
            src: rearCommsArrayRightImage,
        },
        {
            value: 8,
            label: "REAR - COMMS ARRAY BOTH",
            frontSrc: rearCommsArrayRightImage,
            src: rearCommsArrayLeftImage,
        },
        {
            value: 9,
            label: "REAR - LOTSA MISSILES LEFT",
            src: rearLotsaMissilesLeftImage,
        },
        {
            value: 10,
            label: "REAR - LOTSA MISSILES RIGHT",
            src: rearLotsaMissilesRightImage,
        },
        {
            value: 11,
            label: "REAR - LOTSA MISSILES BOTH",
            frontSrc: rearLotsaMissilesRightImage,
            src: rearLotsaMissilesLeftImage,
        },
        {
            value: 12,
            label: "REAR - MICRO MISSILES LEFT",
            src: rearMicroMissilesLeftImage,
        },
        {
            value: 13,
            label: "REAR - MICRO MISSILES RIGHT",
            src: rearMicroMissilesRightImage,
        },
        {
            value: 14,
            label: "REAR - MICRO MISSILES BOTH",
            frontSrc: rearMicroMissilesRightImage,
            src: rearMicroMissilesLeftImage,
        },
        {
            value: 15,
            label: "REAR - MISSILE BOX LEFT",
            src: rearMissileBoxLeftImage,
        },
        {
            value: 16,
            label: "REAR - MISSILE BOX RIGHT",
            src: rearMissileBoxRightImage,
        },
        {
            value: 17,
            label: "REAR - MISSILE BOX BOTH",
            frontSrc: rearMissileBoxRightImage,
            src: rearMissileBoxLeftImage,
        },
        {
            value: 18,
            label: "REAR - MISSILE BOX LEFT",
            src: rearMissilesLeftImage,
        },
        {
            value: 19,
            label: "REAR - MISSILES RIGHT",
            src: rearMissilesRightImage,
        },
        {
            value: 20,
            label: "REAR - MISSILES BOTH",
            frontSrc: rearMissilesRightImage,
            src: rearMissilesLeftImage,
        },
        {
            value: 21,
            label: "REAR - PARTICLE BLASTER LEFT",
            src: rearParticleBlasterLeftImage,
        },
        {
            value: 22,
            label: "REAR - PARTICLE BLASTER RIGHT",
            src: rearParticleBlasterRightImage,
        },
        {
            value: 23,
            label: "REAR - PARTICLE BLASTER BOTH",
            frontSrc: rearParticleBlasterRightImage,
            src: rearParticleBlasterLeftImage,
        },
        {
            value: 24,
            label: "REAR - ROCKETS LEFT",
            src: rearRocketsLeftImage,
        },
        {
            value: 25,
            label: "REAR - ROCKETS RIGHT",
            src: rearRocketsRightImage,
        },
        {
            value: 26,
            label: "REAR - ROCKETS BOTH",
            frontSrc: rearRocketsRightImage,
            src: rearRocketsLeftImage,
        },
        {
            value: 27,
            label: "REAR - TURRET LEFT",
            src: rearTurretLeftImage,
        },
        {
            value: 28,
            label: "REAR - TURRET RIGHT",
            src: rearTurretRightImage,
        },
        {
            value: 29,
            label: "REAR - TURRET BOTH",
            frontSrc: rearTurretRightImage,
            src: rearTurretLeftImage,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "WEAPONS",
            value: "WEAPONS",
        },
        {
            label: "GLOWS",
            value: "ACCESSORIES",
            isGlow: true,
        },
        {
            label: "HEAD",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "L ARM",
            value: "weapon",
        },
        {
            label: "R ARM",
            value: "secondaryWeapon",
        },
        {
            label: "REAR - MOUNT",
            value: "rearMount",
        },
        {
            label: "GLOW 1",
            value: "accessory1",
            isGlow: true,
        },
        {
            label: "GLOW 2",
            value: "accessory2",
            isGlow: true,
        },
        {
            label: "ACCESSORY 1",
            value: "accessory3",
        },
        {
            label: "ACCESSORY 2",
            value: "accessory4",
        },
    ],
    labels: {
        "head": "HEAD",
        "chassis": "CHASSIS",
        "rearMount": "REAR MOUNT",
        "weapon": "L ARM",
        "secondaryWeapon": "R ARM",
        "accessory1": "GLOW 1",
        "accessory2": "GLOW 2",
        "accessory3": "ACCESSORY 1",
        "accessory4": "ACCESSORY 2",
    },
};
