import assault from "./Mechs-Biomecha-Assault";
import ronin from "./Mechs-Biomecha-Ronin";
import cataphract from "./Mechs-Biomecha-Cataphract";
import priest from "./Mechs-Biomecha-Priest";
import hive from "./Mechs-Biomecha-Hive";
import seeder from "./Mechs-Biomecha-Seeder";
import ace from "./Mechs-Biomecha-Ace";
import bastion from "./Mechs-Biomecha-Bastion";
import demolisher from "./Mechs-Biomecha-Demolisher";
import berserker from "./Mechs-Biomecha-Berkserker";
import MechsBiomechaBarricade from "./Mechs-Biomecha-Barricade";
import MechsBiomechaBreacher from "./Mechs-Biomecha-Breacher";
import MechsBiomechaWitch from "./Mechs-Biomecha-Witch";

export default [
    Object.assign({ value: 0, label: "ACE", isPremium: true, isLegendary: true }, ace),
    Object.assign({ value: 1, label: "ASSAULT", isPremium: true, isLegendary: true }, assault),
    Object.assign({ value: 2, label: "BARRICADE", isPremium: true, isLegendary: true }, MechsBiomechaBarricade),
    Object.assign({ value: 3, label: "BASTION", isPremium: true, isLegendary: true }, bastion),
    Object.assign({ value: 4, label: "BERSERKER", isPremium: true, isLegendary: true }, berserker),
    Object.assign({ value: 5, label: "BREACHER", isPremium: true, isLegendary: true }, MechsBiomechaBreacher),
    Object.assign({ value: 6, label: "CATAPHRACT", isPremium: true, isLegendary: true }, cataphract),
    Object.assign({ value: 7, label: "DEMOLISHER", isPremium: true, isLegendary: true }, demolisher),
    Object.assign({ value: 8, label: "HIVE", isPremium: true, isLegendary: true }, hive),
    Object.assign({ value: 9, label: "RONIN", isPremium: true, isLegendary: true }, ronin),
    Object.assign({ value: 10, label: "PRIEST", isPremium: true, isLegendary: true }, priest),
    Object.assign({ value: 11, label: "SEEDER", isPremium: true, isLegendary: true }, seeder),
    Object.assign({ value: 12, label: "WITCH", isPremium: true, isLegendary: true }, MechsBiomechaWitch),
];

export const biomechaTemplateList = [
    {
        label: "DEFAULT",
        value: {"camo1Value":"NONE","camo2Value":"NONE","camo3Value":"NONE","camo4Value":"NONE","camo1TintValue":"none","camo2TintValue":"none","camo3TintValue":"none","camo4TintValue":"none","chassisTintValue":"none","weaponTintValue":"none","secondaryWeaponTintValue":"none"},
    },
    {
        label: "BRASS OCHRE",
        value: {"camo1Value":"0","camo2Value":"7","camo3Value":"1","camo4Value":"3","camo1TintValue":"sepia(100%) saturate(206%) brightness(85%) hue-rotate(345deg) invert(0%) contrast(100%)","camo2TintValue":"sepia(100%) saturate(134%) brightness(70%) hue-rotate(0deg) invert(0%) contrast(100%)","camo3TintValue":"sepia(100%) saturate(148%) brightness(95%) hue-rotate(87deg) invert(0%) contrast(100%)","camo4TintValue":"sepia(100%) saturate(100%) brightness(80%) hue-rotate(0deg) invert(0%) contrast(100%)","chassisTintValue":"sepia(100%) saturate(93%) brightness(99%) hue-rotate(69deg) invert(0%) contrast(100%)","weaponTintValue":"sepia(100%) saturate(52%) brightness(96%) hue-rotate(35deg) invert(0%) contrast(100%)","secondaryWeaponTintValue":"sepia(100%) saturate(332%) brightness(122%) hue-rotate(337deg) invert(0%) contrast(100%)"},
    },
    {
        label: "BUBBLEGUM CRISIS",
        value: {"camo1Value":"7","camo2Value":"0","camo3Value":"3","camo4Value":"8","camo1TintValue":"sepia(100%) saturate(300%) brightness(172%) hue-rotate(300deg) invert(0%) contrast(100%)","camo2TintValue":"sepia(65%) saturate(312%) brightness(200%) hue-rotate(150deg) invert(0%) contrast(100%)","camo3TintValue":"sepia(65%) saturate(263%) brightness(130%) hue-rotate(150deg) invert(0%) contrast(100%)","camo4TintValue":"sepia(100%) saturate(151%) brightness(97%) hue-rotate(261deg) invert(0%) contrast(100%)","chassisTintValue":"sepia(66%) saturate(160%) brightness(98%) hue-rotate(189deg) invert(0%) contrast(100%)","weaponTintValue":"sepia(65%) saturate(424%) brightness(200%) hue-rotate(286deg) invert(0%) contrast(100%)","secondaryWeaponTintValue":"sepia(100%) saturate(300%) brightness(181%) hue-rotate(332deg) invert(0%) contrast(100%)"},
    },
    {
        label: "CHARCOAL SHERBET",
        value: {"camo1Value":"NONE","camo2Value":"7","camo3Value":"3","camo4Value":"4","camo1TintValue":"none","camo2TintValue":"sepia(100%) saturate(448%) brightness(160%) hue-rotate(318deg) invert(0%) contrast(100%)","camo3TintValue":"sepia(100%) saturate(0%) brightness(40%) hue-rotate(345deg) invert(0%) contrast(100%)","camo4TintValue":"none","chassisTintValue":"sepia(100%) saturate(0%) brightness(44%) hue-rotate(300deg) invert(0%) contrast(100%)","weaponTintValue":"sepia(100%) saturate(38%) brightness(160%) hue-rotate(347deg) invert(0%) contrast(100%)","secondaryWeaponTintValue":"sepia(100%) saturate(500%) brightness(108%) hue-rotate(320deg) invert(0%) contrast(100%)"},
    },
    {
        label: "FERROUS OXIDE",
        value: {"camo1Value":"3","camo2Value":"0","camo3Value":"6","camo4Value":"8","camo1TintValue":"sepia(100%) saturate(364%) brightness(66%) hue-rotate(316deg) invert(0%) contrast(100%)","camo2TintValue":"sepia(100%) saturate(500%) brightness(54%) hue-rotate(305deg) invert(0%) contrast(100%)","camo3TintValue":"sepia(100%) saturate(260%) brightness(85%) hue-rotate(337deg) invert(0%) contrast(100%)","camo4TintValue":"sepia(72%) saturate(265%) brightness(97%) hue-rotate(336deg) invert(0%) contrast(100%)","chassisTintValue":"sepia(33%) saturate(239%) brightness(74%) hue-rotate(308deg) invert(0%) contrast(100%)","weaponTintValue":"sepia(100%) saturate(89%) brightness(74%) hue-rotate(332deg) invert(0%) contrast(100%)","secondaryWeaponTintValue":"sepia(100%) saturate(500%) brightness(118%) hue-rotate(325deg) invert(0%) contrast(100%)"},
    },
    {
        label: "HEDGE KNIGHT",
        value: {"camo1Value":"0","camo2Value":"4","camo3Value":"3","camo4Value":"8","camo1TintValue":"sepia(48%) saturate(0%) brightness(187%) hue-rotate(318deg) invert(0%) contrast(100%)","camo2TintValue":"sepia(40%) saturate(352%) brightness(60%) hue-rotate(357deg) invert(0%) contrast(100%)","camo3TintValue":"sepia(48%) saturate(308%) brightness(104%) hue-rotate(360deg) invert(0%) contrast(100%)","camo4TintValue":"sepia(48%) saturate(373%) brightness(44%) hue-rotate(95deg) invert(0%) contrast(100%)","chassisTintValue":"sepia(100%) saturate(127%) brightness(77%) hue-rotate(47deg) invert(0%) contrast(100%)","weaponTintValue":"sepia(48%) saturate(185%) brightness(63%) hue-rotate(74deg) invert(0%) contrast(100%)","secondaryWeaponTintValue":"sepia(100%) saturate(241%) brightness(137%) hue-rotate(328deg) invert(0%) contrast(100%)"},
    },
    {
        label: "LISA FRANK",
        value: {"camo1Value":"6","camo2Value":"3","camo3Value":"5","camo4Value":"8","camo1TintValue":"sepia(100%) saturate(250%) brightness(168%) hue-rotate(153deg) invert(0%) contrast(100%)","camo2TintValue":"sepia(39%) saturate(500%) brightness(89%) hue-rotate(271deg) invert(0%) contrast(100%)","camo3TintValue":"sepia(39%) saturate(185%) brightness(130%) hue-rotate(182deg) invert(0%) contrast(100%)","camo4TintValue":"sepia(41%) saturate(486%) brightness(156%) hue-rotate(251deg) invert(0%) contrast(100%)","chassisTintValue":"sepia(100%) saturate(250%) brightness(99%) hue-rotate(187deg) invert(0%) contrast(100%)","weaponTintValue":"sepia(62%) saturate(178%) brightness(93%) hue-rotate(207deg) invert(0%) contrast(100%)","secondaryWeaponTintValue":"sepia(100%) saturate(411%) brightness(100%) hue-rotate(210deg) invert(0%) contrast(100%)"},
    },
    {
        label: "PAIN",
        value: {"camo1Value":"6","camo2Value":"7","camo3Value":"1","camo4Value":"NONE","camo1TintValue":"sepia(0%) saturate(500%) brightness(45%) hue-rotate(0deg) invert(0%) contrast(100%)","camo2TintValue":"sepia(100%) saturate(300%) brightness(157%) hue-rotate(0deg) invert(0%) contrast(100%)","camo3TintValue":"sepia(100%) saturate(21%) brightness(110%) hue-rotate(0deg) invert(0%) contrast(100%)","camo4TintValue":"sepia(0%) saturate(500%) brightness(45%) hue-rotate(0deg) invert(0%) contrast(100%)","chassisTintValue":"sepia(100%) saturate(186%) brightness(93%) hue-rotate(0deg) invert(0%) contrast(100%)","weaponTintValue":"sepia(25%) saturate(250%) brightness(60%) hue-rotate(150deg) invert(0%) contrast(100%)","secondaryWeaponTintValue":"sepia(100%) saturate(286%) brightness(106%) hue-rotate(341deg) invert(0%) contrast(100%)"},
    },
    {
        label: "PERFECTION",
        value: {"camo1Value":"0","camo2Value":"4","camo3Value":"7","camo4Value":"NONE","camo1TintValue":"sepia(0%) saturate(500%) brightness(45%) hue-rotate(0deg) invert(0%) contrast(100%)","camo2TintValue":"sepia(65%) saturate(293%) brightness(69%) hue-rotate(57deg) invert(0%) contrast(100%)","camo3TintValue":"sepia(100%) saturate(197%) brightness(91%) hue-rotate(215deg) invert(0%) contrast(100%)","camo4TintValue":"sepia(0%) saturate(500%) brightness(45%) hue-rotate(0deg) invert(0%) contrast(100%)","chassisTintValue":"sepia(100%) saturate(186%) brightness(100%) hue-rotate(45deg) invert(0%) contrast(100%)","weaponTintValue":"sepia(25%) saturate(250%) brightness(60%) hue-rotate(150deg) invert(0%) contrast(100%)","secondaryWeaponTintValue":"sepia(100%) saturate(286%) brightness(106%) hue-rotate(341deg) invert(0%) contrast(100%)"},
    },
    {
        label: "TUNDRA SLATE",
        value: {"camo1Value":"0","camo2Value":"3","camo3Value":"4","camo4Value":"NONE","camo1TintValue":"sepia(100%) saturate(0%) brightness(146%) hue-rotate(300deg) invert(0%) contrast(100%)","camo2TintValue":"sepia(100%) saturate(62%) brightness(43%) hue-rotate(141deg) invert(0%) contrast(100%)","camo3TintValue":"sepia(100%) saturate(100%) brightness(24%) hue-rotate(111deg) invert(0%) contrast(100%)","camo4TintValue":"none","chassisTintValue":"sepia(100%) saturate(103%) brightness(81%) hue-rotate(141deg) invert(0%) contrast(100%)","weaponTintValue":"sepia(100%) saturate(55%) brightness(104%) hue-rotate(111deg) invert(0%) contrast(100%)","secondaryWeaponTintValue":"sepia(68%) saturate(366%) brightness(130%) hue-rotate(116deg) invert(0%) contrast(100%)"},
    },
];
