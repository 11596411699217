import { contributorList } from "../Category/components/ArtCreditLink";
import { shadowTypes } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

const imagePath = `${BaseURL}/Mechs/Custom/BDF Akrafena`;

// BODY
const bodyAkrafenaImage = `${imagePath}/Chassis.png`;

// HEADS
const headAkrafenaImage = `${imagePath}/heads/Head - Akrafena.png`;
const headBastionImage = `${imagePath}/heads/Head - Bastion.png`;
const headBlackspotImage = `${imagePath}/heads/Head - Blackspot.png`;
const headBunkerImage = `${imagePath}/heads/Head - Bunker.png`;
const headCalibanImage = `${imagePath}/heads/Head - Caliban.png`;
const headCheddahImage = `${imagePath}/heads/Head - Cheddah.png`;
const headChimeraImage = `${imagePath}/heads/Head - Chimera.png`;
const headCockpitImage = `${imagePath}/heads/Head - Cockpit.png`;
const headCyclopsImage = `${imagePath}/heads/Head - Cyclops.png`;
const headDungamImage = `${imagePath}/heads/Head - Dungam.png`;
const headFTNelsonImage = `${imagePath}/heads/Head - Flight Type Nelson.png`;
const headHazardImage = `${imagePath}/heads/Head - Hazard.png`;
const headHornedImage = `${imagePath}/heads/Head - Horned.png`;
const headHornedHairImage = `${imagePath}/heads/Head - Horned Hair.png`;
const headKazuImage = `${imagePath}/heads/Head - Kazu.png`;
const headMagVisionaryImage = `${imagePath}/heads/Head - Mag Visionary.png`;
const headMagImage = `${imagePath}/heads/Head - Mag.png`;
const headMaliceImage = `${imagePath}/heads/Head - Malice.png`;
const headMecha1Image = `${imagePath}/heads/Head - Mecha 1.png`;
const headMecha2Image = `${imagePath}/heads/Head - Mecha 2.png`;
const headMecha3Image = `${imagePath}/heads/Head - Mecha 3.png`;
const headMecha4Image = `${imagePath}/heads/Head - Mecha 4.png`;
const headMecha5Image = `${imagePath}/heads/Head - Mecha 5.png`;
const headMecha6Image = `${imagePath}/heads/Head - Mecha 6.png`;
const headMecha7Image = `${imagePath}/heads/Head - Mecha 7.png`;
const headMecha8Image = `${imagePath}/heads/Head - Mecha 8.png`;
const headNaturalLawImage = `${imagePath}/heads/Head - Natural Law.png`;
const headNaturalLawHairImage = `${imagePath}/heads/Head - Natural Law Hair.png`;
const headNemesisImage = `${imagePath}/heads/Head - Nemesis.png`;
const headRainImage = `${imagePath}/heads/Head - Rain.png`;
const headRainHairImage = `${imagePath}/heads/Head - Rain Hair.png`;
const headRamshackleImage = `${imagePath}/heads/Head - Ramshackle.png`;
const headRumbleImage = `${imagePath}/heads/Head - Rumble.png`;
const headSkullImage = `${imagePath}/heads/Head - Skull.png`;
const headSlipstreamImage = `${imagePath}/heads/Head - Slipstream.png`;
const headTokugawaImage = `${imagePath}/heads/Head - Tokugawa.png`;
const headVanquisherImage = `${imagePath}/heads/Head - Vanquisher.png`;
const head8BallImage = `${imagePath}/heads/Head - 8 Ball.png`;

// WEAPONS
// LEFT
const weaponsLeftArmImage = `${imagePath}/weapons/left/Front Left - Arm.png`;
const weaponsLeftAssaultRifleImage = `${imagePath}/weapons/left/Front Left - Assault Rifle.png`;
const weaponsLeftAutogunImage = `${imagePath}/weapons/left/Front Left - Autogun.png`;
const weaponsLeftFlameGatlingImage = `${imagePath}/weapons/left/Front Left - Flame Gatling.png`;
const weaponsLeftHeavyCannonImage = `${imagePath}/weapons/left/Front Left - Heavy Cannon.png`;
const weaponsLeftLightGatlingGunImage = `${imagePath}/weapons/left/Front Left - Light Gatling gun.png`;
const weaponsLeftMediumGatlingGunImage = `${imagePath}/weapons/left/Front Left - Medium Gatling gun.png`;
const weaponsLeftHeavyGatlingGunImage = `${imagePath}/weapons/left/Front Left - Heavy Gatling gun.png`;
const weaponsLeftRailgunImage = `${imagePath}/weapons/left/Front Left - Railgun.png`;
const weaponsLeftScoutCannonImage = `${imagePath}/weapons/left/Front Left - Scout Cannon.png`;
const weaponsLeftStarpointSpearImage = `${imagePath}/weapons/left/Front Left - Starpoint Spear.png`;
// RIGHT
const weaponsRightArmImage = `${imagePath}/weapons/right/Front Right - Arm.png`;
const weaponsRightAssaultRifleImage = `${imagePath}/weapons/right/Front Right - Assault Rifle.png`;
const weaponsRightAutogunImage = `${imagePath}/weapons/right/Front Right - Autogun.png`;
const weaponsRightFlameGatlingImage = `${imagePath}/weapons/right/Front Right - Flame Gatling.png`;
const weaponsRightHeavyCannonImage = `${imagePath}/weapons/right/Front Right - Heavy Cannon.png`;
const weaponsRightLightGatlingGunImage = `${imagePath}/weapons/right/Front Right - Light Gatling Gun.png`;
const weaponsRightMediumGatlingGunImage = `${imagePath}/weapons/right/Front Right - Medium Gatling Gun.png`;
const weaponsRightHeavyGatlingGunImage = `${imagePath}/weapons/right/Front Right - Heavy Gatling Gun.png`;
const weaponsRightRailgunImage = `${imagePath}/weapons/right/Front Right - Railgun.png`;
const weaponsRightScoutCannonImage = `${imagePath}/weapons/right/Front Right - Scout Cannon.png`;
const weaponsRightStarpointSpearImage = `${imagePath}/weapons/right/Front Right - Starpoint Spear.png`;


// REAR MOUNT
const rearMountAntennaeImage = `${imagePath}/rear mount/Rear - Antennae Right.png`;
const rearMountAssaultRifleLeftImage = `${imagePath}/rear mount/Rear - Assault Rifle Left.png`;
const rearMountAssaultRifleRightImage = `${imagePath}/rear mount/Rear - Assault Rifle Right.png`;
// const rearMountAssaultRifleDualImage = `${imagePath}/rear mount/Rear - Assault Rifle Dual.png`;
const rearMountCannonImage = `${imagePath}/rear mount/Rear - Cannon Left.png`;
const rearMountLaserMortarLeftImage = `${imagePath}/rear mount/Rear - Laser Mortar Left.png`;
const rearMountLaserMortarRightImage = `${imagePath}/rear mount/Rear - Laser Mortar Right.png`;
// const rearMountLaserMortarDualImage = `${imagePath}/rear mount/Rear - Laser Mortar Dual.png`;
const rearMountMissiles1LeftImage = `${imagePath}/rear mount/Rear - Missiles 1 Left.png`;
const rearMountMissiles1RightImage = `${imagePath}/rear mount/Rear - Missiles 1 Right.png`;
// const rearMountMissiles1DualImage = `${imagePath}/rear mount/Rear - Missiles 1 Dual.png`;
const rearMountMissiles2LeftImage = `${imagePath}/rear mount/Rear - Missiles 2 Left.png`;
const rearMountMissiles2RightImage = `${imagePath}/rear mount/Rear - Missiles 2 Right.png`;
// const rearMountMissiles2DualImage = `${imagePath}/rear mount/Rear - Missiles 2 Dual.png`;

// GLOW
const glowLaserMortartLeftImage = `${imagePath}/glows/Glow - Laser Mortar Left.png`;
const glowLaserMortartRightImage = `${imagePath}/glows/Glow - Laser Mortar Right.png`;
const glowLaserMortartDualImage = `${imagePath}/glows/Glow - Laser Mortar Dual.png`;
const glowRailgunLeftImage = `${imagePath}/glows/Glow - Left Raingun.png`;
const glowRailgunRightImage = `${imagePath}/glows/Glow - Right Railgun.png`;
const glowRailgunDualImage = `${imagePath}/glows/Glow - Raingun Dual.png`;
const glowFlameGatlingLeftImage = `${imagePath}/glows/Glow - Left Flame Gatling.png`;
const glowFlameGatlingRightImage = `${imagePath}/glows/Glow - Right Flame Gatling.png`;
const glowCalibanImage = `${imagePath}/glows/Glow - Caliban.png`;
const glowMagVisionaryImage = `${imagePath}/glows/Glow - Mag Visionary.png`;
const glowSkullImage = `${imagePath}/glows/Glow - Skull.png`;

const previewImage = `${imagePath}/preview.png`;
const restrictedPreviewImage = `${imagePath}/preview-restricted.png`;

const coreImage1 = `${imagePath}/BDF_akrafena_core_color-Volkenhammer.png`;

const bipedLegsShadow = {
    size: shadowTypes.SUPREMELY_LARGE,
    bottom: "85px",
    right: "-26px",
};

export default {
    previewImg: previewImage,
    restrictedPreviewImg: restrictedPreviewImage,
    coreImg: [
        {
            src: coreImage1,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "Volkenhammer",
                url: "https://discord.gg/JUF47G5",
            },
        },
    ],
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "0",
        "rearMountValue": "5",
        "weaponValue": "10",
        "secondaryWeaponValue": "0",
        "accessory1Value": "NONE",
        "accessory2Value": "NONE",
        "accessory3Value": "NONE",
        "accessory4Value": "NONE",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "AKRAFENA",
            src: headAkrafenaImage,
        },
        {
            value: 1,
            label: "BASTION",
            src: headBastionImage,
        },
        {
            value: 2,
            label: "BLACKSPOT",
            src: headBlackspotImage,
        },
        {
            value: 3,
            label: "BUNKER",
            src: headBunkerImage,
        },
        {
            value: 4,
            label: "CALIBAN",
            src: headCalibanImage,
        },
        {
            value: 5,
            label: "CHEDDAH",
            src: headCheddahImage,
        },
        {
            value: 6,
            label: "CHIMERA",
            src: headChimeraImage,
        },
        {
            value: 7,
            label: "COCKPIT",
            src: headCockpitImage,
        },
        {
            value: 8,
            label: "CYCLOPS",
            src: headCyclopsImage,
        },
        {
            value: 9,
            label: "DUNGAM",
            src: headDungamImage,
        },
        {
            value: 10,
            label: "FT NELSON",
            src: headFTNelsonImage,
        },
        {
            value: 11,
            label: "HAZARD",
            src: headHazardImage,
        },
        {
            value: 12,
            label: "HORNED",
            src: headHornedImage,
        },
        {
            value: 13,
            label: "HORNED HAIR",
            src: headHornedHairImage,
        },
        {
            value: 14,
            label: "KAZU",
            src: headKazuImage,
        },
        {
            value: 15,
            label: "MAG",
            src: headMagImage,
        },
        {
            value: 16,
            label: "MAG VISIONARY",
            src: headMagVisionaryImage,
        },
        {
            value: 17,
            label: "MALICE",
            src: headMaliceImage,
        },
        {
            value: 18,
            label: "MECHA 1",
            src: headMecha1Image,
        },
        {
            value: 19,
            label: "MECHA 2",
            src: headMecha2Image,
        },
        {
            value: 20,
            label: "MECHA 3",
            src: headMecha3Image,
        },
        {
            value: 21,
            label: "MECHA 4",
            src: headMecha4Image,
        },
        {
            value: 22,
            label: "MECHA 5",
            src: headMecha5Image,
        },
        {
            value: 23,
            label: "MECHA 6",
            src: headMecha6Image,
        },
        {
            value: 24,
            label: "MECHA 7",
            src: headMecha7Image,
        },
        {
            value: 25,
            label: "MECHA 8",
            src: headMecha8Image,
        },
        {
            value: 26,
            label: "NATURAL LAW",
            src: headNaturalLawImage,
        },
        {
            value: 27,
            label: "NATURAL LAW HAIR",
            src: headNaturalLawHairImage,
        },
        {
            value: 28,
            label: "NEMESIS",
            src: headNemesisImage,
        },
        {
            value: 29,
            label: "RAIN",
            src: headRainImage,
        },
        {
            value: 30,
            label: "RAIN HAIR",
            src: headRainHairImage,
        },
        {
            value: 31,
            label: "RAMSHACKLE",
            src: headRamshackleImage,
        },
        {
            value: 32,
            label: "RUMBLE",
            src: headRumbleImage,
        },
        {
            value: 33,
            label: "SKULL",
            src: headSkullImage,
        },
        {
            value: 34,
            label: "SLIPSTREAM",
            src: headSlipstreamImage,
        },
        {
            value: 35,
            label: "TOKUGAWA",
            src: headTokugawaImage,
        },
        {
            value: 36,
            label: "VANQUISHER",
            src: headVanquisherImage,
        },
        {
            value: 37,
            label: "8 BALL",
            src: head8BallImage,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "BDF AKRAFENA",
            src: bodyAkrafenaImage,
            shadowDetails: bipedLegsShadow,
            originalArtCredit: contributorList.BDF_MFR,
        },
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "ARM",
                backSrc: weaponsLeftArmImage,
            },
            {
                value: 1,
                label: "ASSAULT RIFLE",
                backSrc: weaponsLeftAssaultRifleImage,
            },
            {
                value: 2,
                label: "AUTOGUN",
                backSrc: weaponsLeftAutogunImage,
            },
            {
                value: 3,
                label: "FLAME GATLING",
                backSrc: weaponsLeftFlameGatlingImage,
            },
            {
                value: 4,
                label: "HEAVY CANNON",
                backSrc: weaponsLeftHeavyCannonImage,
            },
            {
                value: 5,
                label: "LIGHT GATLING GUN",
                backSrc: weaponsLeftLightGatlingGunImage,
            },
            {
                value: 6,
                label: "MEDIUM GATLING GUN",
                backSrc: weaponsLeftMediumGatlingGunImage,
            },
            {
                value: 7,
                label: "HEAVY GATLING GUN",
                backSrc: weaponsLeftHeavyGatlingGunImage,
            },
            {
                value: 8,
                label: "RAILGUN",
                backSrc: weaponsLeftRailgunImage,
            },
            {
                value: 9,
                label: "SCOUT CANNON",
                backSrc: weaponsLeftScoutCannonImage,
            },
            {
                value: 10,
                label: "STARPOINT SPEAR",
                backSrc: weaponsLeftStarpointSpearImage,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "ARM",
            src: weaponsRightArmImage,
        },
        {
            value: 1,
            label: "ASSAULT RIFLE",
            src: weaponsRightAssaultRifleImage,
        },
        {
            value: 2,
            label: "AUTOGUN",
            src: weaponsRightAutogunImage,
        },
        {
            value: 3,
            label: "FLAME GATLING",
            src: weaponsRightFlameGatlingImage,
        },
        {
            value: 4,
            label: "HEAVY CANNON",
            src: weaponsRightHeavyCannonImage,
        },
        {
            value: 5,
            label: "LIGHT GATLING GUN",
            src: weaponsRightLightGatlingGunImage,
        },
        {
            value: 6,
            label: "MEDIUM GATLING GUN",
            src: weaponsRightMediumGatlingGunImage,
        },
        {
            value: 7,
            label: "HEAVY GATLING GUN",
            src: weaponsRightHeavyGatlingGunImage,
        },
        {
            value: 8,
            label: "RAILGUN",
            src: weaponsRightRailgunImage,
        },
        {
            value: 9,
            label: "SCOUT CANNON",
            src: weaponsRightScoutCannonImage,
        },
        {
            value: 10,
            label: "STARPOINT SPEAR",
            src: weaponsRightStarpointSpearImage,
        },
    ],
    rearMount: [
        {
            value: 0,
            label: "ANTENNAE",
            frontSrc: rearMountAntennaeImage,
        },
        {
            value: 1,
            label: "ASSAULT RIFLE LEFT",
            src: rearMountAssaultRifleLeftImage,
        },
        {
            value: 2,
            label: "ASSAULT RIFLE RIGHT",
            frontSrc: rearMountAssaultRifleRightImage,
        },
        {
            value: 3,
            label: "ASSAULT RIFLE DUAL",
            src: rearMountAssaultRifleLeftImage,
            frontSrc: rearMountAssaultRifleRightImage,
        },
        {
            value: 4,
            label: "ASSAULT RIFLE + ANTENNAE",
            src: rearMountAssaultRifleLeftImage,
            frontSrc: rearMountAntennaeImage,
        },
        {
            value: 5,
            label: "CANNON",
            src: rearMountCannonImage,
        },
        {
            value: 6,
            label: "CANNON + ANTENNAE",
            src: rearMountCannonImage,
            frontSrc: rearMountAntennaeImage,
        },
        {
            value: 7,
            label: "LASER MORTAR LEFT",
            src: rearMountLaserMortarLeftImage,
        },
        {
            value: 8,
            label: "LASER MORTAR RIGHT",
            frontSrc: rearMountLaserMortarRightImage,
        },
        {
            value: 9,
            label: "LASER MORTAR DUAL",
            src: rearMountLaserMortarLeftImage,
            frontSrc: rearMountLaserMortarRightImage,
        },
        {
            value: 10,
            label: "LASER MORTAR + ANTENNAE",
            src: rearMountLaserMortarLeftImage,
            frontSrc: rearMountAntennaeImage,
        },
        {
            value: 11,
            label: "MISSILES 1 LEFT",
            src: rearMountMissiles1LeftImage,
        },
        {
            value: 12,
            label: "MISSILES 1 RIGHT",
            frontSrc: rearMountMissiles1RightImage,
        },
        {
            value: 13,
            label: "MISSILES 1 DUAL",
            src: rearMountMissiles1LeftImage,
            frontSrc: rearMountMissiles1RightImage,
        },
        {
            value: 14,
            label: "MISSILES 1 + ANTENNAE",
            src: rearMountMissiles1LeftImage,
            frontSrc: rearMountAntennaeImage,
        },
        {
            value: 15,
            label: "MISSILES 2 LEFT",
            src: rearMountMissiles2LeftImage,
        },
        {
            value: 16,
            label: "MISSILES 2 RIGHT",
            frontSrc: rearMountMissiles2RightImage,
        },
        {
            value: 17,
            label: "MISSILES 2 DUAL",
            src: rearMountMissiles2LeftImage,
            frontSrc: rearMountMissiles2RightImage,
        },
        {
            value: 18,
            label: "MISSILES 2 + ANTENNAE",
            src: rearMountMissiles2LeftImage,
            frontSrc: rearMountAntennaeImage,
        },
    ],
    accessory: [
        {
            value: 0,
            label: "LASER MORTAR LEFT",
            src: glowLaserMortartLeftImage,
        },
        {
            value: 1,
            label: "LASER MORTAR RIGHT",
            frontSrc: glowLaserMortartRightImage,
        },
        {
            value: 2,
            label: "LASER MORTAR DUAL",
            frontSrc: glowLaserMortartRightImage,
            src: glowLaserMortartDualImage,
        },
        {
            value: 3,
            label: "RAILGUN LEFT",
            src: glowRailgunLeftImage,
        },
        {
            value: 4,
            label: "RAILGUN RIGHT",
            frontSrc: glowRailgunRightImage,
        },
        {
            value: 5,
            label: "RAILGUN DUAL",
            frontSrc: glowRailgunRightImage,
            src: glowRailgunDualImage,
        },
        {
            value: 6,
            label: "FLAME GATLING LEFT",
            src: glowFlameGatlingLeftImage,
        },
        {
            value: 7,
            label: "FLAME GATLING RIGHT",
            frontSrc: glowFlameGatlingRightImage,
        },
        {
            value: 8,
            label: "FLAME GATLING DUAL",
            frontSrc: glowFlameGatlingRightImage,
            src: glowFlameGatlingLeftImage,
        },
        {
            value: 9,
            label: "CALIBAN",
            frontSrc: glowCalibanImage,
        },
        {
            value: 10,
            label: "MAG VISIONARY",
            frontSrc: glowMagVisionaryImage,
        },
        {
            value: 11,
            label: "SKULL",
            frontSrc: glowSkullImage,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "WEAPONS",
            value: "WEAPONS",
        },
        {
            label: "GLOWS",
            value: "ACCESSORIES",
            isGlow: true,
        },
        {
            label: "HEAD",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "LEFT WEAPON",
            value: "weapon",
        },
        {
            label: "RIGHT WEAPON",
            value: "secondaryWeapon",
        },
        {
            label: "REAR MOUNT",
            value: "rearMount",
        },
        {
            label: "GLOW 1",
            value: "accessory1",
            isGlow: true,
        },
        {
            label: "GLOW 2",
            value: "accessory2",
            isGlow: true,
        },
        {
            label: "GLOW 3",
            value: "accessory3",
            isGlow: true,
        },
        {
            label: "GLOW 4",
            value: "accessory4",
            isGlow: true,
        },
    ],
    labels: {
        "head": "HEAD",
        "chassis": "CHASSIS",
        "rearMount": "REAR MOUNT",
        "weapon": "LEFT WEAPON",
        "secondaryWeapon": "RIGHT WEAPON",
        "accessory1": "GLOW 1",
        "accessory2": "GLOW 2",
        "accessory3": "GLOW 3",
        "accessory4": "GLOW 4",
    },
};
