import BaseURL from "./images/Hosting-BaseURL";
import { shadowTypes } from "../styles/UnitShadow";

const imagePath = `${BaseURL}/Mechs/Custom/FBT-Thor`;

// BODY
const bodyImage = `${imagePath}/THOR_CHASSIS.png`;

// HEADS
const headTHORImage = `${imagePath}/Heads/THOR_HEAD-THOR.png`;
const headCALIBANImage = `${imagePath}/Heads/THOR_HEAD-CALIBAN.png`;
const headHORNEDImage = `${imagePath}/Heads/THOR_HEAD-HORNED.png`;
const headJUDICATORImage = `${imagePath}/Heads/THOR_HEAD-JUDICATOR.png`;
const headNATURALLAWImage = `${imagePath}/Heads/THOR_HEAD-NATURAL-LAW.png`;
const headNATURALLAWHAIRImage = `${imagePath}/Heads/THOR_HEAD-NATURAL-LAW-HAIR.png`;
const headRAINTRANSFORMEDImage = `${imagePath}/Heads/THOR_HEAD-RAIN-TRANSFORMED.png`;
const headRAINTRANSFORMEDHAIRImage = `${imagePath}/Heads/THOR_HEAD-RAIN-TRANSFORMED-HAIR.png`;
const headRIPANDTEARImage = `${imagePath}/Heads/THOR_HEAD-RIP-AND-TEAR.png`;
const headSCOPEHOUNDDAPPERImage = `${imagePath}/Heads/THOR_HEAD-SCOPEHOUND DAPPER.png`;
const headSCOPEHOUNDImage = `${imagePath}/Heads/THOR_HEAD-SCOPEHOUND.png`;
const headSKULLImage = `${imagePath}/Heads/THOR_HEAD-SKULL.png`;
const headTHORNEDImage = `${imagePath}/Heads/THOR_HEAD-THORNED.png`;
const headVICTORYImage = `${imagePath}/Heads/THOR_HEAD-VICTORY.png`;

// WEAPONS
const weaponAUTOMATICSHOTGUNImage = `${imagePath}/Weapons/THOR_WEAPON-AUTOMATIC-SHOTGUN.png`;
const weaponDECAYAXEImage = `${imagePath}/Weapons/THOR_WEAPON_DECAY-AXE.png`;
const weaponLANCEOFUNITYImage = `${imagePath}/Weapons/THOR_WEAPON_LANCE-OF-UNITY.png`;
const weaponMJONLIRMINORImage = `${imagePath}/Weapons/THOR_WEAPON-MJONLIR-MINOR.png`;
const weaponSYMBIOTEWHIPImage = `${imagePath}/Weapons/THOR_WEAPON-SYMBIOTE-WHIP.png`;

// REAR MOUNT
const rearJotunnBladeImage = `${imagePath}/Rear/THOR_REAR_JOTUNN-BLADE.png`;

// ACCESSORY
const accMantleImage = `${imagePath}/Accessories/THOR_ACCESSORY-MANTLE.png`;

// GLOWS
const glowAUTOMATICSHOTGUNImage = `${imagePath}/Glows/THOR_GLOW_AUTOMATIC-SHOTGUN-GLOW.png`;
const glowJOTUNNBLADEImage = `${imagePath}/Glows/THOR_GLOW_JOTUNN-BLADE.png`;
const glowCHASSISImage = `${imagePath}/Glows/THOR_GLOW-CHASSIS.png`;
const glowDECAYAXEImage = `${imagePath}/Glows/THOR_GLOW-DECAY-AXE.png`;
const glowHUDImage = `${imagePath}/Glows/THOR_GLOW-HUD.png`;
const glowMJOLNIRMINORGLOWImage = `${imagePath}/Glows/THOR_GLOW-MJOLNIR-MINOR-GLOW.png`;
const glowMANTLEImage = `${imagePath}/Glows/THOR_ACCESSORY-MANTLE_GLOW.png`;
const glowTHORHEADImage = `${imagePath}/Glows/THOR_GLOW-THOR-HEAD.png`;

const previewImage = `${imagePath}/preview.png`;

const bipedLegsShadow = {
    size: shadowTypes.MEDIUM,
    bottom: "80px",
    right: "57px",
};

export default {
    previewImg: previewImage,
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "0",
        "rearMountValue": "0",
        "weaponValue": "1",
        "secondaryWeaponValue": "NONE",
        "accessory1Value": "7",
        "accessory2Value": "3",
        "accessory3Value": "2",
        "accessory4Value": "1",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "THOR",
            src: headTHORImage,
        },
        {
            value: 1,
            label: "CALIBAN",
            src: headCALIBANImage,
        },
        {
            value: 2,
            label: "HORNED",
            src: headHORNEDImage,
        },
        {
            value: 3,
            label: "JUDICATOR",
            src: headJUDICATORImage,
        },
        {
            value: 4,
            label: "NATURAL LAW",
            src: headNATURALLAWImage,
        },
        {
            value: 5,
            label: "NATURAL LAW HAIR",
            src: headNATURALLAWHAIRImage,
        },
        {
            value: 6,
            label: "RAIN TRANSFORMED",
            src: headRAINTRANSFORMEDImage,
        },
        {
            value: 7,
            label: "RAIN TRANSFORMED HAIR",
            src: headRAINTRANSFORMEDHAIRImage,
        },
        {
            value: 8,
            label: "RIP AND TEAR",
            src: headRIPANDTEARImage,
        },
        {
            value: 9,
            label: "SCOPEHOUND",
            src: headSCOPEHOUNDImage,
        },
        {
            value: 10,
            label: "SCOPEHOUND DAPPER",
            src: headSCOPEHOUNDDAPPERImage,
        },
        {
            value: 11,
            label: "SKULL",
            src: headSKULLImage,
        },
        {
            value: 12,
            label: "THORNED",
            src: headTHORNEDImage,
        },
        {
            value: 13,
            label: "VICTORY",
            src: headVICTORYImage,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "FBT THOR",
            src: bodyImage,
            shadowDetails: bipedLegsShadow,
            originalArtCredit: {
                prefixText: "Created by ",
                title: "Gummies139",
                url: "https://discord.gg/JUF47G5",
            },
        },
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "AUTOMATIC SHOTGUN",
                src: weaponAUTOMATICSHOTGUNImage,
            },
            {
                value: 1,
                label: "DECAY AXE",
                src: weaponDECAYAXEImage,
            },
            {
                value: 2,
                label: "LANCE OF UNITY",
                src: weaponLANCEOFUNITYImage,
            },
            {
                value: 3,
                label: "MJONLIR MINOR",
                src: weaponMJONLIRMINORImage,
            },
            {
                value: 4,
                label: "SYMBIOTE WHIP",
                src: weaponSYMBIOTEWHIPImage,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "MANTLE",
            src: accMantleImage,
        },
    ],
    rearMount: [
        {
            value: 0,
            label: "JOTUNN BLADE",
            src: rearJotunnBladeImage,
        },
    ],
    accessory: [
        {
            value: 0,
            label: "AUTOMATIC SHOTGUN",
            frontSrc: glowAUTOMATICSHOTGUNImage,
        },
        {
            value: 1,
            label: "JOTUNN BLADE",
            src: glowJOTUNNBLADEImage,
        },
        {
            value: 2,
            label: "CHASSIS",
            src: glowCHASSISImage,
        },
        {
            value: 3,
            label: "DECAY AXE",
            frontSrc: glowDECAYAXEImage,
        },
        {
            value: 4,
            label: "HUD",
            frontSrc: glowHUDImage,
        },
        {
            value: 5,
            label: "MJOLNIR MINOR",
            frontSrc: glowMJOLNIRMINORGLOWImage,
        },
        {
            value: 6,
            label: "MANTLE",
            frontSrc: glowMANTLEImage,
        },
        {
            value: 7,
            label: "THOR HEAD",
            frontSrc: glowTHORHEADImage,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "WEAPONS",
            value: "WEAPONS",
        },
        {
            label: "GLOWS",
            value: "ACCESSORIES",
            isGlow: true,
        },
        {
            label: "HEAD",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "WEAPON",
            value: "weapon",
        },
        {
            label: "ACCESSORY",
            value: "secondaryWeapon",
        },
        {
            label: "REAR MOUNT",
            value: "rearMount",
        },
        {
            label: "GLOW 1",
            value: "accessory1",
            isGlow: true,
        },
        {
            label: "GLOW 2",
            value: "accessory2",
            isGlow: true,
        },
        {
            label: "GLOW 3",
            value: "accessory3",
            isGlow: true,
        },
        {
            label: "GLOW 4",
            value: "accessory4",
            isGlow: true,
        },
    ],
    labels: {
        "head": "HEAD",
        "chassis": "CHASSIS",
        "rearMount": "REAR MOUNT",
        "weapon": "WEAPON",
        "secondaryWeapon": "ACCESSORY",
        "accessory1": "GLOW 1",
        "accessory2": "GLOW 2",
        "accessory3": "GLOW 3",
        "accessory4": "GLOW 4",
    },
};
